import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { sidebarSelector } from '@store/index'

/**
 * Dynamic Wrapper Container
 */
@Component({
  selector: 'aff-wrapper-container',
  templateUrl: './wrapper-container.component.html',
  styleUrls: ['./wrapper-container.component.scss']
})
export class WrapperContainer implements OnInit {
  showNavigation: boolean = true;

  navigation$: Observable<boolean>;

  /**
   * Dynamic Wrapper Constructor
   */
  constructor(private store: Store<{ sidebar: boolean }>) { 
  }

  /**
   * @ignore
   */
  ngOnInit() {
  }

}
