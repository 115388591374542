import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { HelperService, CommissionGroupService } from "@services/index";

import * as ENUMS from "@enums/index";
import { IState } from '@models/index';

@Component({
  selector: 'aff-campaign-commission-settings-tab',
  templateUrl: './campaign-commission-settings-tab.component.html',
  styleUrls: ['./campaign-commission-settings-tab.component.scss']
})
export class CampaignCommissionSettingsTabComponent implements OnInit, OnDestroy {
  @Output() getComSettingObjValues = new EventEmitter<any>();
  @Output() onClickSaveComGroups = new EventEmitter<boolean>();
  editMode: boolean = false;
  showCommSettingForm: boolean = false;
  @Input() clickSaved$: Observable<boolean>;
  @Output() getComSettingValues = new EventEmitter<any>();
  @Input() campaignID: string = '';
  @Input() set campaignEditMode(options) { 
    // check edit mode
    // if check mode delete default values
    if (!options) {
      this.commissionSettingsForm.patchValue({ 
        com_group_name: 'Group Default Name'
      })
    }
    this.editMode = options;
    // hide or show commission setting form
    this.showCommSettingForm = !options;
  }

  comSettingEditMode: boolean = false;
  
  public commissionSettingsForm: FormGroup;
  commissionSettingsID: string = '';
  commissionSettings: Observable<IState>;

  errorObj: any = {};

  // enums
  commissionGroupClickApproval = ENUMS.CommissionGroupClickApproval;
  autoCurrency = ENUMS.AutoCurrency;
  zeroOrdersCurrency = ENUMS.FixedCostsCurrency;
  cookieLifetime = ENUMS.CookieLifetime;
  commissionModel = ENUMS.CommissionModel;

  private unsubscribe$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder,
              private commissionGroupService: CommissionGroupService) {
      
      this.createForm();
      this.commissionSettings = this.commissionGroupService.getCommissionGroupsSelector();
   }

  ngOnInit() {
    this.commissionSettings
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          if (state.isSaved) {
            this.showCommSettingForm = false;
          }
          if (!HelperService.isObjectEmpty(state.selectedItem)) {
            this.commissionSettingsID = state.selectedItem.id;
            HelperService.fillFormValues(state.selectedItem, this.commissionSettingsForm);
            this.comSettingEditMode = true;
            this.showCommSettingForm = true;
          } else {
            this.comSettingEditMode = false;
          }
        }
      })

    // click campaign save
    this.clickSaved$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
          const comsetFormValue = this.commissionSettingsForm.value;
          if (!comsetFormValue.sale_commission_active && 
              !comsetFormValue.lead_commission_active &&
              !comsetFormValue.click_commission_active) {
                this.errorObj['message'] = 'Please, select commission value from "Sales" and/or "Leads" and/or "Clicks"';
              } else {
                delete this.errorObj.message;
                this.errorObj = HelperService.checkValidation(this.commissionSettingsForm);
                this.checkDisableValueValidation();                           
                if (HelperService.isObjectEmpty(this.errorObj) && !this.editMode) {
                  this.getComSettingValues.emit(this.commissionSettingsForm.value);
                };
              };
      });
  }

  createForm() {
    this.commissionSettingsForm = this.formBuilder.group({
      campaign_id: new FormControl(this.campaignID),
      com_group_name: new FormControl(null, [Validators.required]),
      sale_commission_active: new FormControl(false, [Validators.required]),
      sale_commission_model: new FormControl(this.commissionModel.CPA, [Validators.required]),
      sale_extra_bonus: new FormControl(false, [Validators.required]),
      sale_extra_bonus_value: new FormControl(null),
      sale_approval: new FormControl(this.commissionGroupClickApproval.MANUAL, [Validators.required]),
      sale_commission: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
      sale_auto_currency: new FormControl(this.autoCurrency.PERCENT, [Validators.required]),
      sale_second_tier: new FormControl(false, [Validators.required]),
      sale_tier_commission: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
      sale_tier_commission_currency: new FormControl(this.autoCurrency.PERCENT, [Validators.required]),
      sale_is_zero_orders: new FormControl(false, [Validators.required]),
      sale_is_zero_commission: new FormControl(false, [Validators.required]),
      sale_is_fixed_cost: new FormControl(false, [Validators.required]),
      sale_fixed_cost_comm: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
      sale_fixed_commission_currency: new FormControl(this.autoCurrency.MONEY, [Validators.required]),
      lead_commission_active: new FormControl(false, [Validators.required]),
      lead_commission_model: new FormControl(this.commissionModel.CPA, [Validators.required]),
      lead_extra_bonus: new FormControl(false, [Validators.required]),
      lead_extra_bonus_value: new FormControl(null),
      lead_approval: new FormControl(this.commissionGroupClickApproval.MANUAL, [Validators.required]),
      lead_commission: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
      lead_second_tier: new FormControl(false, [Validators.required]),
      lead_tier_commission: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
      first_sale_comm: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
      first_second_tier_comm: new FormControl(null, [Validators.required])
      // click_commission_active: new FormControl(false, [Validators.required]),
      // click_commission_model: new FormControl(this.commissionModel.CPA, [Validators.required]),
      // click_extra_bonus: new FormControl(false, [Validators.required]),
      // click_approval: new FormControl(this.commissionGroupClickApproval.AUTO, [Validators.required]),
      // click_commission: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
      // click_second_tier: new FormControl(false, [Validators.required]),
      // click_tier_commission: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
    });

  }

  checkDisableValueValidation(): void {
    const formValues = this.commissionSettingsForm.value;

    if (!formValues.sale_commission_active) {
      delete this.errorObj['sale_commission'];
    } 

    if (!formValues.sale_second_tier) {
      delete this.errorObj['sale_tier_commission'];
      delete this.errorObj['first_second_tier_comm'];
    } 

    if (!formValues.sale_is_fixed_cost) {
      delete this.errorObj['sale_fixed_cost_comm'];
    }

    if (!formValues.lead_commission_active) {
      delete this.errorObj['lead_commission'];
    }

    if (!formValues.lead_second_tier) {
      delete this.errorObj['lead_tier_commission'];
    }

    if (formValues.sale_commission_model !== 3) {
      delete this.errorObj['first_sale_comm'];
      delete this.errorObj['first_second_tier_comm'];
    }

    // if (!formValues.click_commission_active) {
    //   delete this.errorObj['click_commission'];
    // }

    // if (!formValues.click_second_tier)  {
    //   delete this.errorObj['click_tier_commission'];
    // }
    HelperService.scrollOnErrorElement(this.errorObj)
  }

  onShowCommSettingForm(e): void {
    this.showCommSettingForm = false;
    this.createForm();
    setTimeout(() => {
      this.showCommSettingForm = true;
    })
  }

  onSaveComGroups(): void {
    this.onClickSaveComGroups.emit(true);   
    // console.log(this.commissionSettingsForm.value);
    
    if(this.commissionSettingsForm.value.sale_commission_model === 3 && !this.commissionSettingsForm.value.sale_second_tier) {
      delete this.errorObj['first_second_tier_comm'];
      // delete this.errorObj['first_sale_comm'];
    }
    // console.log(this.errorObj, 'comm-settings');
    
    if (HelperService.isObjectEmpty(this.errorObj)) {
      this.commissionSettingsForm.patchValue({ campaign_id: parseInt(this.campaignID) });
      this.comSettingEditMode 
        ? this.commissionGroupService.updateCommissionGroup({ 
            id: this.commissionSettingsID, 
            ...this.commissionSettingsForm.value 
          })
        : this.commissionGroupService.addCommissionGroup(this.commissionSettingsForm.value);
    }
  }

  getChildObjValues(objValues: any): void {
    this.commissionSettingsForm.patchValue(objValues);    
  }
  
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
