import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";

import { toggleSidebar } from "@store/sidebar/sidebar.actions";
import {
  openLoader,
  closeLoader,
  openSaving,
  closeSaving,
} from "@store/components/components.actions";
import { IComponentState, NavigationState, INotification } from "@models/index";
import {
  getNavigationSelector,
  getActivePageSelector,
} from "@store/navigation/navigation.selector";
import {
  fetchNavigation,
  setActivePage,
} from "@store/navigation/navigation.actions";
import { sidebarSelector } from "@store/sidebar/sidebar.selectors";
import {
  getLoadingSelector,
  getNotificationSelector,
} from "@store/components/components.selectors";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  backendUrl = environment.backendUrl;

  constructor(
    private http: HttpClient,
    private store: Store<{
      sidebar: boolean;
      components: IComponentState;
      navigation: NavigationState;
    }>
  ) {}

  onSaveToggle(
    isShow: boolean,
    isError: boolean = false,
    message: string = ""
  ) {
    isShow
      ? this.store.dispatch(openSaving({ isShow, isError, message }))
      : this.store.dispatch(closeSaving());
  }

  getLoaderSelector(): Observable<boolean> {
    return this.store.select(getLoadingSelector);
  }

  onLoaderToggle(isSaved: boolean) {
    isSaved
      ? this.store.dispatch(openLoader())
      : this.store.dispatch(closeLoader());
  }

  async uploadImage(formDate: FormData, resource: string) {
    return this.http
      .post(`${this.backendUrl}/uploads/upload/${resource}`, formDate)
      .toPromise();
  }

  async getGenerateBanner(banner) {
    return this.http
      .post(
        `${this.backendUrl}/promotion-tools/promotion-tool/generate`,
        banner,
        { responseType: "text" as "json" }
      )
      .toPromise();
  }

  getSidebarSelector(): Observable<boolean> {
    return this.store.select(sidebarSelector);
  }

  toggleSidebar() {
    return this.store.dispatch(toggleSidebar());
  }

  getNavigationSelector(): Observable<NavigationState> {
    return this.store.select(getNavigationSelector);
  }

  getNotificationSelector(): Observable<INotification> {
    return this.store.select(getNotificationSelector);
  }

  fetchNavigation() {
    this.store.dispatch(fetchNavigation());
  }

  getNavigation() {
    return this.http.get("/assets/navigation/navigation.json?ver=1");
  }

  getActivePageSelector(): Observable<any> {
    return this.store.select(getActivePageSelector);
  }

  setActivePage(pageSlug: string): void {
    this.store.dispatch(setActivePage({ pageSlug }));
  }
}
