import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ReportsRoutingModule } from "./reports-routing.module";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { MyDatePickerModule } from "mydatepicker";
import { NgSelectModule } from "@ng-select/ng-select";
import { SharedPipesModule } from "@pipes/index";
import { SharedModule } from "@shared/shared.module";
import { SharedDirectivesModule } from "@directives/index";

import {
  AffiliateActivityPage,
  ClickPage,
  TopAffiliatesPage,
  TopCampaignsPage,
  TopPromotionToolsPage,
  TopReferringUrlsPage,
  TopAffiliateFilterComponent,
  TopCampaignFilterComponent,
  TopPromotionToolFilterComponent,
  TopReferringFilterComponent,
  AffiliateActivityFilterComponent,
  ClickFilterComponent,
  ClickPopupComponent,
  CampaignsPage,
  CampaignFilterComponent,
  UserReport,
} from "./index";

@NgModule({
  declarations: [
    AffiliateActivityPage,
    ClickPage,
    TopAffiliatesPage,
    CampaignsPage,
    TopCampaignsPage,
    TopPromotionToolsPage,
    TopReferringUrlsPage,
    TopAffiliateFilterComponent,
    CampaignFilterComponent,
    TopCampaignFilterComponent,
    TopPromotionToolFilterComponent,
    TopReferringFilterComponent,
    AffiliateActivityFilterComponent,
    ClickFilterComponent,
    ClickPopupComponent,
    UserReport,
  ],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    NgxDatatableModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SharedPipesModule,
    MyDatePickerModule,
    NgSelectModule,
    SharedDirectivesModule,
  ],
})
export class ReportsPageModule {}
