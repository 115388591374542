import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PermissionGuard } from '@guards/index';
import {
    BannersListPage, BannersAddPage } from './index';

const routes: Routes = [
    {
        path: '',
        component: BannersListPage,
        canActivate: [PermissionGuard],
    },
    {
        path: 'promotion-tool',
        canActivate: [PermissionGuard],
        children: [
            { path: '', component: BannersAddPage, data: { breadcrumb: 'Add Promotion Tool' } },
            { path: ':id', component: BannersAddPage, data: { breadcrumb: 'Edit Promotion Tool' } }
        ]
    }
];

@NgModule({
  imports: [
    RouterModule.forChild(
        routes
    )
  ],
  exports: [
    RouterModule
  ]
})
export class BannersPageRoutingModule {}
