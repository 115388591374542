import { Injectable, OnDestroy } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, zip, Subject } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";

import { PageService, HelperService } from "@services/index";

@Injectable()
export class PermissionGuard implements CanActivate, OnDestroy {
  slugArr: string[] = [];

  store: any = {
    resources: [],
    role: [],
  };

  private unsubscribe$ = new Subject<void>();

  constructor(private router: Router, private pageService: PageService) {
    zip(
      this.pageService.getRecoursesSelector(),
      this.pageService.getRoleSelector()
    )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([res, role]: any) => {
        let _role = this.store.role.length ? this.store.role[0] : role;

        if (!res.isLoaded || HelperService.isObjectEmpty(_role)) {
          return false;
        }

        this.store.resources = res.data;
        this.store.role = role.permissions;
      });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // check resources and permissions in store to avoid request
    if (this.store.resources.length && this.store.role.length) {
      return this.checkPermissions(
        state.url,
        this.store.resources,
        this.store.role
      );
    } else {
      // after page refresh
      return zip(
        this.pageService.getResources(),
        this.pageService.getRoleByToken()
      ).pipe(
        take(1),
        map(([res, role]: any) => {
          if (!res.length || HelperService.isObjectEmpty(role)) {
            this.router.navigate(["/dashboard"]);
            return false;
          }

          return this.checkPermissions(state.url, res, role.permissions);
        })
      );
    }
  }

  checkPermissions(url: string, getResources: any[], role: any[]): boolean {
    this.slugArr = url.split("?")[0].split("/");
    this.slugArr.shift();
    let resources = [...getResources];

    for (let slug of this.slugArr) {
      slug = this.hasNumber(slug) ? "[id]" : slug;
      const findRes = resources.find((res) => res.slug === slug);
      if (!findRes) {
        this.router.navigate(["/dashboard"]);
        return false;
      }
      const findPerm = role.find((perm) => perm.resource_id === findRes.id);

      if (
        !findPerm ||
        (findRes.readable && !findPerm.read) ||
        (findRes.creatable && !findPerm.create) ||
        (findRes.updatable && !findPerm.update)
      ) {
        this.router.navigate(["/dashboard"]);
        return false;
      }

      resources = findRes.children;
    }

    return true;
  }

  hasNumber(myString): boolean {
    return /\d/.test(myString);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
