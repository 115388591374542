import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { SharedService } from "@services/index";

@Component({
  selector: "aff-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent {
  @Input() items: any[] = [];
  @Input() showNavigation: boolean = true;

  activePage$: Observable<string>;
  activePageSlug: string = "";
  parentPageSlug: string = "";

  private unsubscribe$ = new Subject<void>();

  constructor(private router: Router, private sharedService: SharedService) {
    this.activePage$ = this.sharedService.getActivePageSelector();
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.sharedService.setActivePage(event.url);
      }
    });
  }
  ngOnInit(): void {
    this.activePage$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((pageSlug) => {
        if (!pageSlug) return;
        this.activePageSlug = pageSlug;
        this.parentPageSlug = "/" + pageSlug.split("/")[1];
        console.log(this.parentPageSlug);
      });

      console.log(this.items);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
