import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Status } from '@enums/index';
import { 
  GeneratedCommissionService, 
  HelperService, 
  PageService } from '@services/index';
import { IPage } from '@interfaces/index';
import { IState } from '@models/index';
import { GeneratedCommissionStatus, GeneratedCommissionComType } from '@enums/index';

@Component({
  selector: 'aff-commissions-history-list-page',
  templateUrl: './commissions-history-list-page.component.html',
  styleUrls: ['./commissions-history-list-page.component.scss']
})
export class CommissionsHistoryListPage implements OnInit {
  genCommissions$: Observable<IState>;
  rows = [];
  selectedRows = [];
  showSelection: boolean = false;
  loadingIndicator: boolean = true;
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  genCommStatus = GeneratedCommissionStatus;
  
  orderId: string = '';
  approvePopup: boolean = false;
  selectedRow: {};

  affiliateManagers$: Observable<IState>;
  private unsubscribe$ = new Subject<void>();
  
  constructor(private generatedCommissionService: GeneratedCommissionService,
              private pageService: PageService) { 
                // set page breadcrum
                pageService.changePageInfo({ breadcrum: ['Commissions History']});
                
                this.genCommissions$ = generatedCommissionService.getGeneratedCommissionsSelector();
                generatedCommissionService.fetchGeneratedCommissions(1, { status: 'approved,rejected' });
              }

  ngOnInit() {
    this.genCommissions$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => { 
        if (!HelperService.isObjectEmpty(state.selectedItem)) {
          this.approvePopup = true;
        }

        if (state.isLoaded) {
          this.rows = [];
          for (const genCommission of state.data) {
            if(genCommission.commission_type === 'lead') {
              genCommission.sale_order_amount = '';
            }
            
            const row = {
              id: genCommission.id,
              dateCreated: HelperService.dateFormater(genCommission.created_at),
              commissionType: HelperService.getEnumValue(GeneratedCommissionComType, genCommission.commission_type),
              commission: genCommission.commission,
              affiliate: genCommission.user.email,
              userId: genCommission.user_id,
              campaign: genCommission.campaign ? genCommission.campaign.name : '',
              campaignStatus: genCommission.campaign ? Status[genCommission.campaign.status] : '',
              saleOrderId: genCommission.sale_order_id,
              saleOrderAmount: genCommission.sale_order_amount,
              commissionStatus: HelperService.getEnumValue(this.genCommStatus, genCommission.status), 
              actionDate: genCommission.data.length ? HelperService.dateFormater(genCommission.updated_at) : '',
              actionId: genCommission.data.length ? genCommission.data[0].order_id : ''
            };
            this.rows.push(row);
          }
        }
        this.rows = [...this.rows];
        // get pagination options
        this.page.currentPage = state.currentPage - 1;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
      });
  }

  onApproveToggle(row: any): void {
    this.selectedRow = row;
    this.generatedCommissionService.fetchGeneratedCommission(row.id);
  }

  setPage(e): void {
    this.selectedRows = [];
    this.generatedCommissionService.fetchGeneratedCommissions(e.offset + 1, { status: 'approved,rejected' });
  }
  
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}