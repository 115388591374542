import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { environment } from "../../../environments/environment";
import { HelperService } from "../helper/helper.service";
import { fetchChannel, getChannel } from "../../store/channel/channel.actions";
import { Observable } from "rxjs";
import { IState } from "@models/index";
import {
  getChannelSelector,
  getChannelsSelector,
} from "@store/channel/channel.selectors";

@Injectable({
  providedIn: "root",
})
export class ChannelService {
  backendUrl = environment.backendUrl;

  constructor(
    private http: HttpClient,
    private store: Store<{ channel: IState }>
  ) {}

  getChannelsSelector(): Observable<IState> {
    return this.store.select(getChannelsSelector);
  }

  getChannelSelector(): Observable<any> {
    return this.store.select(getChannelSelector);
  }

  fetchChannels(page, filterOptions): void {
    this.store.dispatch(fetchChannel({ page, filterOptions }));
  }

  fetchChannel(id): void {
    this.store.dispatch(getChannel({ id }));
  }

  getChannels(page: number, filterOptions: any = {}) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);

    return this.http.get(`${this.backendUrl}/channels?${queryString}`);
  }

  getChannel(id: string) {
    return this.http.get(`${this.backendUrl}/channels/channel/${id}`);
  }
}
