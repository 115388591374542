import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

/**
 * Dynamic Button Component
 */
@Component({
  selector: 'aff-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  /**
   * Get Button Value Text from parent
   */
  @Input() text: string = '';
  @Input() hoverClass: string = '';

  @Input() icon: string = '';

  /**
   * Get Button Type from parent
   */
  @Input() type: string = 'button';

  /**
   * Get Button Name for form from parent
   */
  @Input() name: ''; // for output

  /**
   * Get Button options from parent
   */
  @Input() options: any = {};

  /**
   * Handle Click Event for sending to parent
   */
  @Output() onClicked = new EventEmitter();
  
  @Input() disabled: boolean = false;

  iconTypes: any = {
    save: 'save',
    remove: 'garbage-green',
    add: 'add',
    back: 'back-arrow',
    remove_blue: 'garbage-blue',
    check: 'check'
  }

  constructor() { }

  /**
   * @ignore
   */
  ngOnInit() {
  }

  /**
   * Detect Button Click
   */
  onClick() {
    const option = {
      value: true,
      name: this.name
    };
    this.onClicked.emit(option);
  }

}
