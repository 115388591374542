import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { map, mergeMap, catchError, tap } from "rxjs/operators";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  fetchResources,
  fetchResourcesSuccess,
  fetchResourcesFaild,
  fetchRoles,
  fetchRolesSuccess,
  fetchRolesFaild,
  fetchRole,
  fetchRoleSuccess,
  fetchRoleFaild,
  updatePermissions,
  updatePermissionsSuccess,
  updatePermissionsFaild,
  fetchUserStatus,
  fetchUserStatusSuccess,
  fetchUserStatusFaild,
  fetchUserType,
  fetchUserTypeSuccess,
  fetchUserTypeFaild,
  fetchUserTypes,
  fetchUserTypesSuccess,
  fetchUserTypesFaild,
  addUserType,
  addUserTypeSuccess,
  addUserTypeFailure,
  updateUserType,
  updateUserTypeSuccess,
  updateUserTypeFailure,
  fetchRoleByToken,
  fetchRoleByTokenSuccess,
  fetchRoleByTokenFaild,
  deleteUserType,
  deleteUserTypeSuccess,
  deleteUserTypeFailure,
  getEnums,
  getEnumsSuccess,
  getEnumsFailure,
  getHelp,
  getHelpSuccess,
  getHelpFailure,
} from "./page.actions";

import { PageService } from "@services/page/page.service";
import { SharedService } from "@services/shared/shared.service";

@Injectable()
export class PageEffects {
  loadResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchResources),
      mergeMap(() =>
        this.pageService.getResources().pipe(
          map((res) => {
            return fetchResourcesSuccess({ resources: res });
          }),
          catchError((error) => {
            return of(fetchResourcesFaild({ error }));
          })
        )
      )
    )
  );

  loadRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchRoles),
      mergeMap(() =>
        this.pageService.getRoles().pipe(
          map((res) => {
            return fetchRolesSuccess({ roles: res });
          }),
          catchError((error) => {
            return of(fetchRolesFaild({ error }));
          })
        )
      )
    )
  );

  getRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchRole),
      mergeMap((action) =>
        this.pageService.getRole(action.id).pipe(
          map((role) => {
            return fetchRoleSuccess({ role });
          }),
          catchError((error) => {
            return of(fetchRoleFaild({ error }));
          })
        )
      )
    )
  );

  getRoleByToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchRoleByToken),
      mergeMap(() =>
        this.pageService.getRoleByToken().pipe(
          map((role) => {
            return fetchRoleByTokenSuccess({ role });
          }),
          catchError((error) => {
            return of(fetchRoleByTokenFaild({ error }));
          })
        )
      )
    )
  );

  updatePermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePermissions),
      mergeMap((action) =>
        this.pageService.insertPermissions(action.permissions).pipe(
          map((role) => {
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
            return updatePermissionsSuccess({ role });
          }),
          catchError((error) => {
            this.sharedService.onLoaderToggle(false);
            return of(updatePermissionsFaild({ error }));
          })
        )
      )
    )
  );

  loadUserStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUserStatus),
      mergeMap((action) =>
        this.pageService.getUserStatuses(action.filterOptions).pipe(
          map((res) => {
            return fetchUserStatusSuccess({ userStatus: res });
          }),
          catchError((error) => {
            return of(fetchUserStatusFaild({ error }));
          })
        )
      )
    )
  );

  getUserType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUserType),
      mergeMap((action) =>
        this.pageService.getUserType(action.id).pipe(
          map((userType) => {
            return fetchUserTypeSuccess({ userType });
          }),
          catchError((error) => {
            return of(fetchUserTypeFaild({ error }));
          })
        )
      )
    )
  );

  loadUserTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUserTypes),
      mergeMap(() =>
        this.pageService.getUserTypes().pipe(
          map((res) => {
            return fetchUserTypesSuccess({ userTypes: res });
          }),
          catchError((error) => {
            return of(fetchUserTypesFaild({ error }));
          })
        )
      )
    )
  );

  addUserType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addUserType),
      mergeMap((action) =>
        this.pageService.insertUserType(action.userType).pipe(
          map((userType) => {
            return addUserTypeSuccess({ userType: userType });
          }),
          tap((res) => {
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
          }),
          catchError((error) => {
            return of(addUserTypeFailure({ error }));
          })
        )
      )
    )
  );

  updateUserType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserType),
      mergeMap((action) =>
        this.pageService.renewUserType(action.userType).pipe(
          map((userType) => {
            return updateUserTypeSuccess({ userType: userType });
          }),
          tap((res) => {
            this.sharedService.onLoaderToggle(false);
            this.sharedService.onSaveToggle(true);
          }),
          catchError((error) => {
            // this.sharedService.onLoaderToggle(false);
            return of(updateUserTypeFailure({ error }));
          })
        )
      )
    )
  );

  deleteUserType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteUserType),
      mergeMap((action) =>
        this.pageService.removeUserType(action.id).pipe(
          map(() => {
            return deleteUserTypeSuccess({ id: action.id });
          }),
          catchError((error) => {
            this.sharedService.onLoaderToggle(false);
            return of(deleteUserTypeFailure({ error }));
          })
        )
      )
    )
  );

  getEnums$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEnums),
      mergeMap((action) =>
        this.pageService.getEnums(action.types).pipe(
          map((enums) => {
            return getEnumsSuccess({ enums });
          }),
          catchError((error) => {
            return of(getEnumsFailure({ error }));
          })
        )
      )
    )
  );

  getHelp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getHelp),
      mergeMap((action) =>
        this.pageService.getHelp(action.key).pipe(
          map((help) => {
            return getHelpSuccess({ help });
          }),
          catchError((error) => {
            return of(getHelpFailure({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private pageService: PageService,
    private sharedService: SharedService
  ) {}
}
