import { Component, OnInit, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { filter, takeUntil, tap } from "rxjs/operators";
import {
  ReportService,
  PageService,
  BannerService,
  ChannelService,
  CampaignService,
  EventCategoryService,
  AffiliateService,
} from "@services/index";
import { IState } from "@models/index";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "aff-user-report",
  templateUrl: "./user-report.component.html",
  styleUrls: ["./user-report.component.scss"],
})
export class UserReport implements OnInit, OnDestroy {
  form: FormGroup = new FormGroup({
    category_id: new FormControl(),
    channel_id: new FormControl(),
    campaign_id: new FormControl(),
    userId: new FormControl(null),
    from: new FormControl(),
    to: new FormControl(),
  });
  rows: any[] = [];

  affiliates: any[] = [];
  events: any[] = [];
  eventIds: any[] = [];
  categories: any[] = [];
  channels: any[] = [];
  campaigns: any[] = [];

  datePickerOptions: any;

  unsubscribe$ = new Subject<void>();

  constructor(
    private reportService: ReportService,
    private pageService: PageService,
    private categoryService: EventCategoryService,
    private affiliateService: AffiliateService,
    private channelService: ChannelService,
    private campaignService: CampaignService
  ) {}

  ngOnInit() {
    this.getCategories();
    this.getAffiliates();
    this.getChannels();

    this.pageService
      .getDatePickerOptionsSelector()
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((options) => (this.datePickerOptions = options))
      )
      .subscribe();

    this.categoryService
      .getEventCategoriesSelector()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((state) => !!state.data.length),
        tap((state) => (this.categories = state.data)),
        tap((state) => this.setEvent(state.data[0])),
        tap((state) =>
          this.form.controls.category_id.patchValue(state.data[0].id)
        )
      )
      .subscribe();

    this.channelService
      .getChannelsSelector()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((list) => !!list.data.length),
        tap((list) => (this.channels = list.data))
      )
      .subscribe();

    this.campaignService
      .getCampaignsSelector()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((list) => !!list.data.length),
        tap((list) => (this.campaigns = list.data))
      )
      .subscribe();

    this.affiliateService
      .getAffiliatesSelector()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ data }) => !!data.length),
        tap(({ data }) => (this.affiliates = data))
      )
      .subscribe();

    this.form
      .get("category_id")
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        tap((category_id) => this.getCampaigns(category_id))
      )
      .subscribe();

    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((value) => {
          const data = { ...value };
          if (data.from) {
            data.from = data.from.formatted;
          }
          if (data.to) {
            data.to = data.to.formatted;
          }

          this.reportService.fetchUsers(1, { ...data });
        })
      )
      .subscribe();

    this.reportService
      .getReportsSelector()
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((state) => this.storeRows(state))
      )
      .subscribe();
  }

  setEvent({ event_types }) {
    this.events = event_types;
    this.eventIds = [];

    event_types.forEach((item) => {
      this.eventIds.push(item.id);

      item.childrens.forEach((item) => this.eventIds.push(item.id));
    });
  }

  private getCategories() {
    this.categoryService.fetchEventCategories(1, {
      with_events: 1,
    });
  }

  private getAffiliates() {
    this.affiliateService.fetchAffiliates(1, { full: 1 });
  }

  private getCampaigns(category_id) {
    this.campaignService.fetchCampaigns(1, {
      with_events: 1,
      category_id,
    });
  }

  private getChannels() {
    this.channelService.fetchChannels(1, {});
  }

  private storeRows(state: IState) {
    if (state.isLoaded) {
      this.rows = [];
    }
    this.rows = state.data.map((item) => {
      const events = new Set(item.event_types);

      this.eventIds.forEach((id) => (item[id] = events.has(id)));

      delete item.event_types;

      return item;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
