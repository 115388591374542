import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { IPage } from "@interfaces/index";
import { IState } from "@models/index";
import { TrackingService, ReportService, HelperService } from "@services/index";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "aff-click-popup",
  templateUrl: "./click-popup.component.html",
  styleUrls: ["./click-popup.component.scss"],
})
export class ClickPopupComponent implements OnInit, OnDestroy {
  @Input() clickID: any = {};

  rows = [];
  loadingIndicator: boolean = true;
  selectedRows = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0,
  };
  click$: Observable<IState>;
  unsubscribe$ = new Subject<void>();

  constructor(private trackingService: TrackingService) {
    this.rows = [];
    this.click$ = this.trackingService.getEventTypesSelector();
  }

  ngOnInit() {
    this.trackingService.fetchClick(1, this.clickID);
    const correct = (el) => {
      return el
        .split("_")
        .map((i, key) => {
          if (key === 0) {
            return i.charAt(0).toUpperCase() + i.substr(1);
          }
          return i;
        })
        .toString()
        .replace(",", " ");
    };

    this.click$.pipe(takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state.isLoaded) {
        this.rows = [];

        for (const click of state.data) {
          const row = {
            eventId: click.event_id,
            eventType: click.event_type || correct(click.default_type),
            eventInfo: click.event_info || "",
            eventDate: click.event_date,
          };
          this.rows.push(row);
        }
      }
      this.rows = [...this.rows];
      // get pagination options
      this.page.currentPage = state.currentPage - 1;
      this.page.perPage = state.perPage;
      this.page.maxPages = state.maxPages;
      this.page.total = state.total;
      // close loader
      this.loadingIndicator = false;
    });
  }

  setPage(e): void {
    this.trackingService.fetchClick(e.offset + 1, this.clickID);
  }

  onSelect(e): void {
    this.selectedRows = e.selected;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
