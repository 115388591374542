import { Component, OnInit, OnDestroy } from "@angular/core";
import { PageService } from "@services/page/page.service";
import { IPage, IFormOptions } from "@interfaces/index";
import { combineLatest, Observable, Subject } from "rxjs";
import {
  takeUntil,
  debounceTime,
  distinctUntilChanged,
  filter,
  tap,
  finalize,
  switchMap,
} from "rxjs/operators";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { HelperService } from "@services/helper/helper.service";
import { IModalvalue } from "@shared-models/index";
import { AffiliateManagersService } from "../../../services/affiliate-managers/affiliate-managers.service";
import {
  CampaignService,
  EventCategoryService,
  TrackingService,
  UserService,
} from "@services/index";

@Component({
  selector: "aff-event-categories",
  templateUrl: "./event-categories.component.html",
  styleUrls: ["./event-categories.component.scss"],
})
export class EventCategoriesPage implements OnInit, OnDestroy {
  form: FormGroup;
  formType: "edit" | "create" = "create";

  confirmationModal: boolean = false;
  deleteValues: string = "";

  isFormOpen: boolean = false;
  loadingIndicator: boolean = true;

  rows: any[] = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0,
  };

  managers: any[];
  campaigns: any[];
  eventTypes: any[];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private eventCategoryService: EventCategoryService,
    private trackingService: TrackingService,
    private pageService: PageService,
    private fb: FormBuilder,
    private userService: UserService,
    private affiliateManagersService: AffiliateManagersService,
    private campaingService: CampaignService
  ) {
    this.setForms();
    this.setBreadcrum();
    this.getManager();

    this.form
      .get("user_id")
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged(),
        tap((user_id) => {
          this.campaingService.fetchCampaigns(1, { managers: [user_id] });
          this.trackingService.fetchParentEventTypes(1, { user_id });
        }),
        tap((user_id) =>
          user_id
            ? this.form.get("campaigns").enable()
            : this.form.get("campaigns").disable()
        ),
        tap((user_id) =>
          user_id
            ? this.form.get("event_types").enable()
            : this.form.get("event_types").disable()
        ),
        switchMap(() =>
          combineLatest([
            this.campaingService.getCampaignsSelector(),
            this.trackingService.getParentEventTypesSelector(),
          ])
        ),
        tap(([campaigns, parents]) => {
          this.campaigns = campaigns.data;
          this.eventTypes = parents;
        })
      )
      .subscribe();

    this.eventCategoryService.fetchEventCategories(1, { with_events: 1,});
  }

  ngOnInit(): void {
    this.eventCategoryService
      .getEventCategoriesSelector()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ isLoaded }) => isLoaded),
        tap(({ data }) => (this.rows = data)),
        tap((state) => this.setPagination(state)),
        tap(() => (this.loadingIndicator = false))
      )
      .subscribe();

    this.eventCategoryService
      .getEventCategorySelector()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res.id) {
          this.form.patchValue({
            id: res.id,
            name: res.name,
            user_id: res.user_id,
            campaigns: res.campaigns
              ? res.campaigns.map((item) => item.id)
              : [],
            event_types: res.event_types
              ? res.event_types.map((item) => item.id)
              : [],
          });
        }
      });
  }

  setPage(page) {
    this.eventCategoryService.fetchEventCategories(page.offset + 1, {});
  }

  onCreate() {
    this.formType = "create";

    this.openFormModal();
    this.form.reset();
  }

  onEdit(row: any): void {
    this.formType = "edit";
    this.eventCategoryService.fetchEventCategory(row.id);

    this.openFormModal();
  }

  onDelete(row: any): void {
    this.deleteValues = `Event Category: <br><br> ${row.name}`;

    this.form.get("id").patchValue(row.id);
    this.openConfirmeModal();
  }

  onDeleteConfirm(options: IModalvalue): void {
    if (options.confirm) {
      this.eventCategoryService.deleteEventCategory(this.form.value.id);
    }

    this.form.get("id").reset();
    this.closeConfirmeModal();
  }

  onChangeFormValue(options: IFormOptions): void {
    this.form.patchValue({ [options.name]: options.value });
  }

  submit() {
    if (!this.form.valid) return;

    const method =
      this.formType === "create"
        ? this.eventCategoryService.addEventCategory.bind(
            this.eventCategoryService
          )
        : this.eventCategoryService.updateEventCategory.bind(
            this.eventCategoryService
          );

    method(this.form.value);

    this.closeFormModal();
    this.form.reset();
  }

  private setPagination(state) {
    this.page = {
      currentPage: state.currentPage - 1,
      perPage: state.perPage,
      maxPages: state.maxPages,
      total: state.total,
    };
  }

  private getManager() {
    this.userService
      .getProfileSelector()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((user) => !HelperService.isObjectEmpty(user)),
        tap(({ isManager }) => !isManager && this.getAllManagers()),
        filter(({ isManager }) => isManager),
        tap((a) => console.log(a)),
        tap(({ userId }) => this.form.patchValue({ user_id: +userId }))
      )
      .subscribe();
  }

  private getAllManagers() {
    this.affiliateManagersService.fetchAffiliateManagers(1, {});

    this.affiliateManagersService
      .getAffiliateManagersSelector()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ isLoaded }) => isLoaded),
        tap(({ data }) => (this.managers = data))
      )
      .subscribe();
  }

  private openFormModal(): void {
    this.isFormOpen = true;
  }

  closeFormModal(): void {
    this.isFormOpen = false;
  }

  private openConfirmeModal() {
    this.confirmationModal = true;
  }

  private closeConfirmeModal() {
    this.confirmationModal = false;
  }

  private setForms() {
    this.form = this.fb.group({
      id: [null],
      name: [null, [Validators.required]],
      user_id: [null, [Validators.required]],
      campaigns: [{ value: null, disabled: true }, [Validators.required]],
      event_types: [{ value: null, disabled: true }],
    });
  }

  private setBreadcrum() {
    this.pageService.changePageInfo({
      breadcrum: ["Tracking", "Event Categories"],
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
