import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {
  ListComponent,
  LineChartComponent,
  FilterComponent,
  ColumnChartComponent,
  CardComponent,
  BlockComponent,
  ButtonComponent,
  CheckboxComponent,
  InputComponent,
  SelectSingleComponent,
  SelectMultyComponent,
  TextAreaComponent,
  PopupComponent,
  RadioComponent,
  NotificationAlertComponent,
  CustomSelectComponent,
  ModalComponent,
  ImgPopupComponent,
  HelpQuestionMarkComponent,
} from './index';
import {AffButtonComponent} from "@shared/aff-button/aff-button.component";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzIconModule} from "ng-zorro-antd";
import { DndUploadComponent } from './dnd-upload/dnd-upload.component';
import { SharedDirectivesModule } from '@directives/shared-directives.module';
import { TabRadioComponent } from './formGroup/tab-radio/tab-radio.component';

/**
 * Array of shared components
 */
const SHARED_MODULES = [
  ListComponent,
  LineChartComponent,
  AffButtonComponent,
  FilterComponent,
  ColumnChartComponent,
  CardComponent,
  BlockComponent,
  ButtonComponent,
  CheckboxComponent,
  InputComponent,
  SelectSingleComponent,
  SelectMultyComponent,
  TextAreaComponent,
  PopupComponent,
  RadioComponent,
  NotificationAlertComponent,
  CustomSelectComponent,
  ModalComponent,
  ImgPopupComponent,
  HelpQuestionMarkComponent,
    LineChartComponent,
    FilterComponent,
    ColumnChartComponent,
    CardComponent,
    BlockComponent,
    ButtonComponent,
    CheckboxComponent,
    InputComponent,
    SelectSingleComponent,
    SelectMultyComponent,
    TextAreaComponent,
    PopupComponent,
    RadioComponent,
    NotificationAlertComponent,
    CustomSelectComponent,
    ModalComponent,
    ImgPopupComponent,
    HelpQuestionMarkComponent,
    DndUploadComponent,
    TabRadioComponent
]

@NgModule({
  declarations: [
    SHARED_MODULES,
    DndUploadComponent,
    TabRadioComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NzButtonModule,
    NzIconModule,
    SharedDirectivesModule
  ],
  exports: [
    SHARED_MODULES
  ]
})

/**
 * Module for shared components
 */
export class SharedModule {
}
