import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { IState } from "@models/index";
import {
  fetchTopAffiliates,
  fetchTopCampaigns,
  fetchTopPromotion,
  fetchTopreferringUrls,
  fetchBalanceSheet,
  fetchChannels,
  fetchGeneratedCommission,
  fetchAffiliateActivity,
  fetchClicks,
  fetchCampaingsReport,
  fetchCampaigns,
  fetchUsers,
} from "../../store/report/report.actions";
import { HelperService } from "../helper/helper.service";
import {
  getReportsSelector,
  getReportSelector,
} from "@store/report/report.selectors";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  backendUrl = environment.backendUrl;

  constructor(
    private http: HttpClient,
    private store: Store<{ campaign: IState }>
  ) {}

  getReportsSelector(): Observable<IState> {
    return this.store.select(getReportsSelector);
  }

  getReportSelector(): Observable<any> {
    return this.store.select(getReportSelector);
  }

  fetchUsers(page, filterOptions) {
    this.store.dispatch(fetchUsers({ page, filterOptions }));
  }

  fetchCampaigns(page, filterOptions) {
    this.store.dispatch(fetchCampaigns({ page, filterOptions }));
  }

  fetchCampaingsReport(page, filterOptions): void {
    this.store.dispatch(fetchCampaingsReport({ page, filterOptions }));
  }

  fetchTopAffiliates(page, filterOptions): void {
    this.store.dispatch(fetchTopAffiliates({ page, filterOptions }));
  }

  fetchTopCampaigns(page, filterOptions): void {
    this.store.dispatch(fetchTopCampaigns({ page, filterOptions }));
  }

  fetchTopPromotions(page, filterOptions): void {
    this.store.dispatch(fetchTopPromotion({ page, filterOptions }));
  }

  fetchTopreferringUrls(page, filterOptions): void {
    this.store.dispatch(fetchTopreferringUrls({ page, filterOptions }));
  }

  fetchBalanceSheet(page, filterOptions): void {
    this.store.dispatch(fetchBalanceSheet({ page, filterOptions }));
  }

  fetchChannels(page, filterOptions): void {
    this.store.dispatch(fetchChannels({ page, filterOptions }));
  }

  fetchGeneratedCommission(page, filterOptions): void {
    this.store.dispatch(fetchGeneratedCommission({ page, filterOptions }));
  }

  fetchAffiliateActivity(page, filterOptions): void {
    this.store.dispatch(fetchAffiliateActivity({ page, filterOptions }));
  }

  fetchClicks(page, filterOptions): void {
    this.store.dispatch(fetchClicks({ page, filterOptions }));
  }

  getUsers(page: number, filterOptions: any = {}) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);

    return this.http.get(
      `${this.backendUrl}/reports/user-report?${queryString}`
    );
  }

  getCampaigns(page: number, filterOptions: any = {}) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);

    return this.http.get(
      `${this.backendUrl}/reports/campaigns-report?${queryString}`
    );
  }

  getTopAffiliates(page: number, filterOptions: any = {}) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);
    return this.http.get(
      `${this.backendUrl}/reports/top-affiliates-report?${queryString}`
    );
  }

  getCampaignsReport(page: number, filterOptions: any = {}) {
    console.log(filterOptions);

    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);
    return this.http.get(
      `${this.backendUrl}/reports/campaigns-report?${queryString}`
    );
  }

  getTopCampaigns(page: number, filterOptions: any = {}) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);
    return this.http.get(
      `${this.backendUrl}/reports/top-campaigns?${queryString}`
    );
  }

  getTopPromotions(page: number, filterOptions: any = {}) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);
    return this.http.get(
      `${this.backendUrl}/reports/top-promotions?${queryString}`
    );
  }

  getTopRefUrls(page: number, filterOptions: any = {}) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);
    return this.http.get(
      `${this.backendUrl}/reports/referring-urls?${queryString}`
    );
  }

  getBalanceSheet(page: number, filterOptions: any = {}) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);
    return this.http.get(
      `${this.backendUrl}/reports/balance-sheet?${queryString}`
    );
  }

  getChannel(page: number, filterOptions: any = {}) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);
    return this.http.get(`${this.backendUrl}/reports/channels?${queryString}`);
  }

  getGeneratedComm(page: number, filterOptions: any = {}) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);
    return this.http.get(
      `${this.backendUrl}/reports/generated-commissions?${queryString}`
    );
  }

  getAffiliateAvtivity(page: number, filterOptions: any = {}) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);
    return this.http.get(
      `${this.backendUrl}/reports/affiliate-activity?${queryString}`
    );
  }

  getClicks(page: number, filterOptions: any = {}) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);
    return this.http.get(`${this.backendUrl}/reports/clicks?${queryString}`);
  }
}
