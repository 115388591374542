import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-banners-list-header',
  templateUrl: './banners-list-header.component.html',
  styleUrls: ['./banners-list-header.component.scss']
})
export class BannersListHeaderComponent implements OnInit {
  @Input() count: number;

  @Output() delete = new EventEmitter()
  @Output() cancel = new EventEmitter()
  constructor() { }

  ngOnInit() {
  }

  handleDelete() {
    this.delete.emit()
  }

  handleCancel() {
    this.cancel.emit()
  }
}
