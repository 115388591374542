import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  fetchChannel,
  fetchChannelSuccess,
  fetchChannelFailure,
  getChannel,
  getChannelSuccess,
  getChannelFailure,
} from "./channel.actions";
import { of } from "rxjs";
import { map, mergeMap, catchError } from "rxjs/operators";
import { ChannelService, PageService } from "@services/index";

@Injectable()
export class ChannelEffects {
  loadChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchChannel),
      mergeMap((action) =>
        this.channelService.getChannels(action.page, action.filterOptions).pipe(
          map((res) => {
            return fetchChannelSuccess({ channel: res });
          }),
          catchError((error) => {
            return of(fetchChannelFailure({ error }));
          })
        )
      )
    )
  );

  getChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getChannel),
      mergeMap((action) =>
        this.channelService.getChannel(action.id).pipe(
          map((res) => {
            return getChannelSuccess({ channel: res });
          }),
          catchError((error) => {
            return of(getChannelFailure({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private channelService: ChannelService,
    private pageService: PageService
  ) {}
}
