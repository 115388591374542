import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { IPage } from '@interfaces/index';
import { takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { PaymentMethodsService, ConfigService, AffiliateService, HelperService } from '@services/index';
import { IState } from "@models/index";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'aff-affiliates-payouts-page',
  templateUrl: './affiliates-payouts-page.component.html',
  styleUrls: ['./affiliates-payouts-page.component.scss']
})
export class AffiliatesPayoutsPage implements OnInit, OnDestroy {

  affiliatePayoutsForm: FormGroup;
  affiliateID: string = '';

  paymentMethods$: Observable<IState>;
  affiliate$: Observable<any>;
  affiliate: any = {};

  userId;

  errorObj: any = {};

  paymentMethods: any;

  lists: any = {
    paymentMethods: []
  };

  treshold$: Observable<any>;
  treshold;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private paymentMethodsService: PaymentMethodsService,
    private configService: ConfigService,
    private route: ActivatedRoute,
    private affiliateService: AffiliateService,
  ) {
    this.affiliatePayoutsForm = this.formBuilder.group({
      payment_method_id: new FormControl(null, [Validators.required]),
      min_payout: new FormControl(null),
      payment_requisite: new FormControl(null, [Validators.required])
    });

    this.paymentMethods$ = this.paymentMethodsService.getPaymentMethodsSelector();
    this.treshold$ = this.configService.getconfigsSelector();
    this.affiliate$ = this.affiliateService.getAffiliateSelector();

    this.paymentMethodsService.fetchPaymentMethods(1, {});
    this.configService.fetchTreshold();
    this.affiliateService.fetchFilteredAffiliates( 1, {})

  }

  ngOnInit() {

    // get affiliate id
    this.affiliateID = this.route.snapshot.params.id;

    if (this.affiliateID) {
      this.affiliateService.fetchAffiliate(this.affiliateID);
    }

    this.affiliate$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(affiliate => {
      // check mode
      if (!HelperService.isObjectEmpty(affiliate)) {
        this.affiliate = affiliate;
        this.userId = affiliate.id;

        // this.affiliatePayoutsForm.value.owner= affiliate.payment_method_id;
        this.paymentMethods = affiliate.payment_method;
        // this.affiliatePayoutsForm.value.payment_requisite = this.convertPaymentToString(affiliate.payment_requisite, affiliate.payment_method_id);
      }
      HelperService.fillFormValues(affiliate, this.affiliatePayoutsForm);

      this.affiliatePayoutsForm.patchValue({
        payment_requisite: this.convertPaymentToString(affiliate.payment_requisite, affiliate.payment_method)
      });
    });


    this.treshold$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(state => {
      for (const treshold of state.data) {
        this.treshold = treshold.value;
      }
    });

    this.paymentMethods$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.lists.paymentMethods = state.data;
        }
      });

  }

  private convertPaymentToString = (data, payment): string => {
    if(data){
      data = JSON.parse(data);
      if(data.bank_wire_transfer){
        if (payment.name === "Bank Wire Transfer") {
          let result = '';
          result += `Full Name: ${data.bank_wire_transfer.full_name || ''} \n`;
          result += `Address: ${data.bank_wire_transfer.address || ''} \n`;
          result += `Bank Name: ${data.bank_wire_transfer.bank_name || ''} \n`;
          result += `Bank Address: ${data.bank_wire_transfer.bank_address || ''} \n`;
          result += `Bank Account Type: ${data.bank_wire_transfer.bank_account_type || ''} \n`;
          result += `Bank Routing Number: ${data.bank_wire_transfer.bank_routing || ''} \n`;
          result += `Bank BIC or SWIFT code: ${data.bank_wire_transfer.bank_code || ''} \n`;
          result += `IBAN or Bank Account Number: ${data.bank_wire_transfer.iban || ''}`;
    
          return result;
        } else {
          return `Account Data: ${data[payment.name.toLowerCase().split(' ').join('_')].account_data || ''}`;
        }
      }else{
        if (payment.id === 1) {
          let result = '';
          result += `Full Name: ${data.full_name || ''} \n`;
          result += `Address: ${data.address || ''} \n`;
          result += `Bank Name: ${data.bank_name || ''} \n`;
          result += `Bank Address: ${data.bank_address || ''} \n`;
          result += `Bank Account Type: ${data.bank_account_type || ''} \n`;
          result += `Bank Routing Number: ${data.bank_routing || ''} \n`;
          result += `Bank BIC or SWIFT code: ${data.bank_code || ''} \n`;
          result += `IBAN or Bank Account Number: ${data.iban || ''}`;
    
          return result;
        } else if (payment.id === 4) {
          return `PayPal Email: ${data.email || ''}`;
        }
      }
      
    }

    return '';
  };

  onSelectedValue(payMethod): void {
    this.affiliatePayoutsForm.patchValue({ payment_method_id: payMethod ? payMethod.id : null });
  }

  saveAffiliatePayMethod() {
    let affPayoutObj = {userId: this.userId, id: this.affiliatePayoutsForm.value.payment_method_id, requisite: this.affiliatePayoutsForm.value.payment_requisite};

    this.errorObj = HelperService.checkValidation(this.affiliatePayoutsForm);
    if (HelperService.isObjectEmpty(this.errorObj)) {
        this.paymentMethodsService.updateUserPayout({userId: this.userId, id: this.affiliatePayoutsForm.value.payment_method_id, requisite: this.affiliatePayoutsForm.value.payment_requisite});
    }
  }

  onChangeFormValue(options) {
    switch(options.name) {
      case 'save':
        this.saveAffiliatePayMethod();
        break;
      default:
        this.affiliatePayoutsForm.patchValue({ [options.name]: options.value });
        break;
    }

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
