import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';

/**
 * Dynamic Textarea Component
 */
@Component({
  selector: 'aff-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent implements OnInit {
  @ViewChild('inputElement', { static: true }) inputElement: ElementRef;

  type: string = '';

  @Input() options: any = {
    placeholder: 'placeholder',
    name: '',
    type: 'text',
    value: ''
  };

  @Input() class: string = '';
  @Input() style: any = {};
  @Input() disabled: boolean = false;
  @Input() error: string = '';
  @Input() autoFocus: boolean = false;
  @Input() helper: string;
  @Input() autocomplete: string;
  @Input() value = '';
  @Output() onOutPutValue = new EventEmitter();

  constructor() { }

  ngOnInit() {
    if (this.autoFocus) {
      this.inputElement.nativeElement.focus();
    }
  }

  onChange(inputValue) {
    const options = {
      value: this.type === 'file' ? inputValue.target.files : inputValue.target.value,
      name: this.inputElement.nativeElement.name
    }
    this.onOutPutValue.emit(options);
  }

}
