import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';

import { CampaignService, HelperService, PageService } from "@services/index";
import { IState } from "@models/index";
import { IPage } from '@interfaces/index';
import { Status } from '@enums/index';
import { IModalvalue } from '@shared-models/index';


@Component({
  selector: "aff-campaign-list-page",
  templateUrl: "./campaign-list-page.component.html",
  styleUrls: ["./campaign-list-page.component.scss"]
})
export class CampaignListPage implements OnInit, OnDestroy {
  /**
   * Rows
   */
  rows = [];
  loadingIndicator: boolean = true;
  selectedRows = [];
  page: IPage = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0
  };

  confirmationModal: boolean = false;
  deleteValues: string = '';

  campaing$: Observable<IState>;
  private unsubscribe$ = new Subject<void>();

  /**
   * Campaign List Constructor
   * @param router
   */
  constructor(
    private router: Router,
    private campaignService: CampaignService,
    private pageService: PageService
  ) {
    // set page breadcrum
    pageService.changePageInfo({ breadcrum: ['Campaigns']});

    this.campaing$ = this.campaignService.getCampaignsSelector();
  }

  /**
   * @ignore
   */
  ngOnInit() {
    // get campaign list
    this.campaignService.fetchCampaigns(1, {});

    // listen campaign
    this.campaing$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.isLoaded) {
          this.rows = [];
          for (const campaign of state.data) {
            const row = {
              id: campaign.id,
              name: campaign.name,
              affiliateManager: campaign.user.email,
              commission: campaign.commissionOverview,
              createDate: HelperService.dateFormater(campaign.created_at),
              validForm: HelperService.dateFormaterwithoutHours(campaign.active_from),
              validUntil: HelperService.dateFormaterwithoutHours(campaign.active_to),
              status: Status[campaign.status],
              deleted_at: campaign.deleted_at ? campaign.deleted_at : ''
            };
            this.rows.push(row);
          }
        }
        this.rows = [...this.rows];
        // get pagination options
        this.page.currentPage = state.currentPage - 1;
        this.page.perPage = state.perPage;
        this.page.maxPages = state.maxPages;
        this.page.total = state.total;
        // close loader
        this.loadingIndicator = false;
    });
  }

  onAction(action: string, row: any): void {
    switch (action) {
      case "delete":
        this.deleteValues = `Campaign: <br><br> ${row.name}`;
        this.selectedRows= [{ id: row.id }];
        this.confirmationModal = true;
        break;

      case "edit":
        this.router.navigate([`/campaigns/campaign/${row.id}`])
        break;

      case 'unarchive':      
        let archivedCampaign = {
          archived: 'true'
        };
        this.campaignService.unarchiveCampaign(row.id, archivedCampaign );
        break;

      default:
        break;
    }
  }

  onDeleteConfirm(options: IModalvalue): void {
    if (options.confirm) {
      for (const selectedRow of this.selectedRows) {
        this.campaignService.deleteCampaign(selectedRow.id);
      }
      this.selectedRows = [];
    }
    this.confirmationModal = false;
  }

  setPage(e): void {
    this.selectedRows = [];
    this.campaignService.fetchCampaigns(e.offset + 1, {});
  }

  onSelect(e): void {
    this.selectedRows = e.selected;
  }

  /**
   * Handle Router Change
   */
  onAddCampaign(): void {
    this.router.navigate(["/campaigns/campaign"]);
  }

  /**
   * Handle Delete Campaign
   */
  onDeleteCampaign(): void {
    if (this.selectedRows.length) {
      this.deleteValues = '';
      this.confirmationModal = true;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
