import { createReducer, on } from "@ngrx/store";
import {
  changePageInfo,
  changeDateOption,
  resetOption,
  fetchResources,
  fetchResourcesSuccess,
  fetchResourcesFaild,
  fetchRoles,
  fetchRolesSuccess,
  fetchRolesFaild,
  fetchRole,
  fetchRoleSuccess,
  fetchRoleFaild,
  updatePermissions,
  updatePermissionsSuccess,
  updatePermissionsFaild,
  fetchUserStatus,
  fetchUserStatusSuccess,
  fetchUserStatusFaild,
  fetchUserType,
  fetchUserTypeSuccess,
  fetchUserTypeFaild,
  fetchUserTypes,
  fetchUserTypesSuccess,
  fetchUserTypesFaild,
  addUserType,
  addUserTypeSuccess,
  addUserTypeFailure,
  updateUserType,
  updateUserTypeSuccess,
  updateUserTypeFailure,
  fetchRoleByToken,
  fetchRoleByTokenSuccess,
  fetchRoleByTokenFaild,
  deleteUserType,
  deleteUserTypeSuccess,
  deleteUserTypeFailure,
  getEnums,
  getEnumsSuccess,
  getEnumsFailure,
  getHelp,
  getHelpSuccess,
  getHelpFailure,
} from "./page.actions";
import { PageState } from "@models/index";

const initialState: PageState = {
  breadcrum: ["Dashboard"],
  pageURL: "",
  datePickerOptions: {
    dateFormat: "yyyy-mm-dd",
    height: "37px",
    inline: false,
  },
  resources: {
    data: [],
    isLoaded: false,
    error: null,
  },
  enums: {
    data: [],
    isLoaded: false,
    error: null,
  },
  roles: {
    data: [],
    isLoaded: false,
    error: null,
    selectedItem: {},
  },
  permissions: {
    error: null,
    isUpdated: false,
  },
  userStatus: {
    data: [],
    isLoaded: false,
    error: null,
    selectedItem: {},
  },
  userTypes: {
    isLoaded: false,
    data: [],
    selectedItem: {},
    error: null,
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0,
  },
  help: {
    error: null,
    key: "",
    text: "",
  },
};

const _pageReducer = createReducer(
  initialState,
  on(changePageInfo, (state, { page }) => {
    return { ...state, ...page };
  }),
  on(changeDateOption, (state, { option }) => {
    const payload = {
      datePickerOptions: { ...state.datePickerOptions, ...option },
    };

    return { ...state, ...payload };
  }),
  on(resetOption, (state, { optionName }) => {
    const payload = {
      datePickerOptions: {
        ...state.datePickerOptions,
        [optionName]: { year: 0, month: 0, day: 0 },
      },
    };

    return { ...state, ...payload };
  }),
  on(fetchResources, (state) => {
    const payload = {
      resources: {
        data: [],
        isLoaded: false,
        error: null,
      },
    };
    return { ...state, ...payload };
  }),
  on(fetchResourcesSuccess, (state, { resources }) => {
    const payload = {
      resources: {
        data: resources,
        isLoaded: true,
      },
    };

    return { ...state, ...payload };
  }),
  on(fetchResourcesFaild, (state, { error }) => {
    const payload = {
      resources: {
        error,
      },
    };

    return { ...state, ...payload };
  }),
  on(fetchRoles, (state) => {
    const payload = {
      roles: {
        data: [],
        isLoaded: false,
        error: null,
        selectedItem: {},
      },
    };
    return { ...state, ...payload };
  }),
  on(fetchRolesSuccess, (state, { roles }) => {
    const payload = {
      roles: {
        data: roles,
        isLoaded: true,
      },
    };

    return { ...state, ...payload };
  }),
  on(fetchRolesFaild, (state, { error }) => {
    const payload = {
      roles: {
        error,
      },
    };

    return { ...state, ...payload };
  }),
  on(fetchRole, (state) => {
    const payload = {
      roles: {
        error: null,
        selectedItem: {},
      },
    };
    return { ...state, ...payload };
  }),
  on(fetchRoleSuccess, (state, { role }) => {
    const payload = {
      roles: {
        selectedItem: role,
      },
    };

    return { ...state, ...payload };
  }),
  on(fetchRoleFaild, (state, { error }) => {
    const payload = {
      roles: {
        error,
      },
    };

    return { ...state, ...payload };
  }),
  on(fetchRoleByToken, (state) => {
    const payload = {
      roles: {
        error: null,
        selectedItem: {},
      },
    };
    return { ...state, ...payload };
  }),
  on(fetchRoleByTokenSuccess, (state, { role }) => {
    const payload = {
      roles: {
        selectedItem: role,
      },
    };

    return { ...state, ...payload };
  }),
  on(fetchRoleByTokenFaild, (state, { error }) => {
    const payload = {
      roles: {
        error,
      },
    };

    return { ...state, ...payload };
  }),
  on(updatePermissions, (state, { permissions }) => {
    const payload = {
      permissions: {
        error: null,
        isUpdated: false,
      },
    };

    return { ...state, ...payload };
  }),
  on(updatePermissionsSuccess, (state, { role }) => {
    const payload = {
      roles: {
        selectedItem: { ...state.roles.selectedItem, ...role },
      },
      permissions: {
        error: null,
        isUpdated: true,
      },
    };
    return { ...state, ...payload };
  }),
  on(updatePermissionsFaild, (state, { error }) => {
    const payload = {
      permissions: {
        error: null,
        isUpdated: false,
      },
    };

    return { ...state, ...payload };
  }),
  on(fetchUserStatus, (state, { filterOptions }) => {
    const payload = {
      userStatus: {
        data: [],
        isLoaded: false,
        error: null,
        selectedItem: {},
      },
    };
    return { ...state, ...payload };
  }),
  on(fetchUserStatusSuccess, (state, { userStatus }) => {
    const payload = {
      userStatus: {
        data: userStatus,
        isLoaded: true,
      },
    };

    return { ...state, ...payload };
  }),
  on(fetchUserStatusFaild, (state, { error }) => {
    const payload = {
      userStatus: {
        error,
      },
    };

    return { ...state, ...payload };
  }),

  on(fetchUserType, (state, { id }) => {
    console.log(state);

    const payload = {
      userTypes: {
        ...state.userTypes,
        error: null,
        selectedItem: {},
      },
    };
    return { ...state, ...payload };
  }),
  on(fetchUserTypeSuccess, (state, { userType }) => {
    console.log(state);

    const payload = {
      userTypes: {
        ...state.userTypes,
        selectedItem: userType,
      },
    };
    return { ...state, ...payload };
  }),
  on(fetchUserTypeFaild, (state, { error }) => {
    const payload = {
      userTypes: {
        ...state.userTypes,
        error,
      },
    };
    return { ...state, ...payload };
  }),

  on(fetchUserTypes, (state) => {
    const payload = {
      userTypes: {
        ...state.userTypes,
        data: [],
        isLoaded: false,
        error: null,
        selectedItem: {},
      },
    };
    return { ...state, ...payload };
  }),
  on(fetchUserTypesSuccess, (state, { userTypes }) => {
    const payload = {
      userTypes: {
        ...state.userTypes,
        isLoaded: true,
        data: userTypes.data,
        total: userTypes.total,
        maxPages: userTypes.maxPages,
        perPage: userTypes.perPage,
        currentPage: userTypes.currentPage,
      },
    };
    return { ...state, ...payload };
  }),
  on(fetchUserTypesFaild, (state, { error }) => {
    const payload = {
      userTypes: {
        ...state.userTypes,
        error,
      },
    };
    return { ...state, ...payload };
  }),

  on(addUserType, (state, { userType }) => {
    const payload = {
      userTypes: {
        ...state.userTypes,
        isSaved: false,
        error: null,
      },
    };
    return { ...state, ...payload };
  }),

  on(addUserTypeSuccess, (state, { userType }) => {
    if (state.userTypes.data.length === 30) {
      state.userTypes.data.pop();
    }
    state.userTypes.data.unshift(userType);

    const payload = {
      userTypes: {
        ...state.userTypes,

        isSaved: true,
      },
    };
    return { ...state, ...payload };
  }),

  on(addUserTypeFailure, (state, { error }) => {
    const payload = {
      userTypes: {
        ...state.userTypes,
        error,
      },
    };

    return { ...state, ...payload };
  }),

  on(updateUserType, (state, { userType }) => {
    const payload = {
      userTypes: {
        ...state.userTypes,
        isSaved: false,
        error: null,
      },
    };
    return { ...state, ...payload };
  }),
  on(updateUserTypeSuccess, (state, { userType }) => {
    const index = state.userTypes.data.findIndex(
      (type) => type.id === userType.id
    );
    state.userTypes.data[index] = userType;

    const payload = {
      userTypes: {
        ...state.userTypes,
        isSaved: true,
      },
    };

    return { ...state, ...payload };
  }),
  on(updateUserTypeFailure, (state, { error }) => {
    const payload = {
      userTypes: {
        ...state.userTypes,
        error,
      },
    };
    return { ...state, ...payload };
  }),

  on(deleteUserType, (state, { id }) => {
    const payload = {
      userTypes: {
        ...state.userTypes,
        error: null,
      },
    };
    return { ...state, ...payload };
  }),
  on(deleteUserTypeSuccess, (state, { id }) => {
    const payload = {
      userTypes: {
        ...state.userTypes,
        data: state.userTypes.data.filter((item) => item.id !== id),
      },
    };
    return { ...state, ...payload };
  }),
  on(deleteUserTypeFailure, (state, { error }) => {
    const payload = {
      userTypes: {
        ...state.userTypes,
        error,
      },
    };
    return { ...state, ...payload };
  }),

  on(getEnums, (state, { types }) => {
    const payload = {
      enums: {
        isLoaded: false,
        error: null,
      },
    };
    return { ...state, ...payload };
  }),
  on(getEnumsSuccess, (state, { enums }) => {
    const payload = {
      enums: {
        isLoaded: true,
        data: enums,
      },
    };
    return { ...state, ...payload };
  }),
  on(getEnumsFailure, (state, { error }) => {
    const payload = {
      enums: {
        isLoaded: true,
        error,
      },
    };
    return { ...state, ...payload };
  }),

  on(getHelp, (state, { key }) => {
    const payload = {
      help: {
        ...state.help,
        error: null,
      },
    };
    return { ...state, ...payload };
  }),
  on(getHelpSuccess, (state, { help }) => {
    const payload = {
      help: {
        ...state.help,
        [help.key]: help.text,
      },
    };
    return { ...state, ...payload };
  }),
  on(getHelpFailure, (state, { error }) => {
    const payload = {
      help: {
        ...state.help,
        error,
      },
    };
    return { ...state, ...payload };
  })
);

export function pageReducer(state, action) {
  return _pageReducer(state, action);
}
