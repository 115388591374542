import { createReducer, on } from "@ngrx/store";
import {
  fetchCampaign,
  fetchCampaignSuccess,
  fetchCampaignFailure,
  getCampaign,
  getCampaignSuccess,
  getCampaignFailure,
  deleteCampaign,
  deleteCampaignSuccess,
  deleteCampaignFailure,
  addCampaign,
  addCampaignSuccess,
  addCampaignFailure,
  updateCampaign,
  updateCampaignSuccess,
  updateCampaignFailure,
  fetchCampaignsByAffiliate,
  fetchCampaignsByAffiliateSuccess,
  fetchCampaignsByAffiliateFailure,
  unarchiveCampaign,
  unarchiveCampaignSuccess,
  unarchiveCampaignFailure,
} from "./campaign.actions";

import { IState } from "@models/index";

const initialState: IState = {
  isLoaded: false,
  data: [],
  selectedItem: {},
  error: null,
  total: 0,
  maxPages: 0,
  perPage: 0,
  currentPage: 0,
};

const _campaignReducer = createReducer(
  initialState,
  on(fetchCampaign, (state, { page, filterOptions }) => {
    return state;
  }),
  on(fetchCampaignSuccess, (state, { campaign }) => {
    const payload = {
      isLoaded: true,
      data: campaign.data,
      total: campaign.total,
      maxPages: campaign.maxPages,
      perPage: campaign.perPage,
      currentPage: campaign.currentPage,
      selectedItem: {},
    };

    return { ...state, ...payload };
  }),
  on(fetchCampaignFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  }),
  on(getCampaign, (state, { id }) => {
    const payload = {
      isLoaded: false,
    };
    return { ...state, ...payload };
  }),
  on(getCampaignSuccess, (state, { campaign }) => {
    const payload = {
      isLoaded: true,
      selectedItem: campaign,
    };
    return { ...state, ...payload };
  }),
  on(getCampaignFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  }),
  on(fetchCampaignsByAffiliate, (state, { page, userID }) => {
    const payload = {
      isLoaded: false,
    };
    return { ...state, ...payload };
  }),
  on(fetchCampaignsByAffiliateSuccess, (state, { campaigns }) => {
    const payload = {
      isLoaded: true,
      data: campaigns.data,
      total: campaigns.total,
      maxPages: campaigns.maxPages,
      perPage: campaigns.perPage,
      currentPage: campaigns.currentPage,
      selectedItem: {},
    };
    return { ...state, ...payload };
  }),
  on(fetchCampaignsByAffiliateFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  }),
  on(deleteCampaign, (state, { id }) => {
    const payload = {
      isLoaded: false,
    };
    return { ...state, ...payload };
  }),
  on(deleteCampaignSuccess, (state, { id }) => {
    const payload = {
      isLoaded: true,
      // data: state.data.filter(item => item.id !== id)
    };
    return { ...state, ...payload };
  }),
  on(deleteCampaignFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  }),
  on(addCampaign, (state, { campaign }) => {
    const payload = {
      isLoaded: false,
    };
    return { ...state, ...payload };
  }),
  on(addCampaignSuccess, (state, { campaign }) => {
    const payload = {
      isLoaded: true,
    };
    return { ...state, ...payload };
  }),
  on(addCampaignFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  }),
  on(updateCampaign, (state, { campaign }) => {
    const payload = {
      isLoaded: false,
    };
    return { ...state, ...payload };
  }),
  on(updateCampaignSuccess, (state, { campaign }) => {
    const index = state.data.findIndex((camp) => camp.id === campaign.id);
    state[index] = campaign;
    const payload = {
      isLoaded: true,
      selectedItem: campaign,
    };
    return { ...state, ...payload };
  }),
  on(updateCampaignFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  }),
  on(unarchiveCampaign, (state, { id, archivedCampaign }) => {
    const payload = {
      isLoaded: false,
    };
    return { ...state, ...payload };
  }),
  on(unarchiveCampaignSuccess, (state, { id }) => {
    const payload = {
      isLoaded: true,
    };
    return { ...state, ...payload };
  }),
  on(unarchiveCampaignFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  })
);

export function campaingReducer(state, action) {
  return _campaignReducer(state, action);
}
