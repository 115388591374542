import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";

import { PageService, SharedService } from "@services/index";
import { NavigationState } from "@models/index";

/**
 * Component for rendering dynamic breadcrumb component.
 */
@Component({
  selector: "aff-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent implements OnInit {
  navigationItems: any[];
  pageBreadcrum$: Observable<string[]>;
  navigation$: Observable<NavigationState>;

  constructor(
    private pageService: PageService,
    private sharedService: SharedService
  ) {
    // get navigation
    this.navigation$ = this.sharedService.getNavigationSelector();

    // select page breadcum
    this.pageBreadcrum$ = this.pageService.getPageBreadcrumSelector();
  }

  ngOnInit() {
    this.navigation$.subscribe((navigation) => {
      if (navigation.isLoaded) {
        this.navigationItems = navigation.data;
      }
    });
  }

  getNavItem(navigationItems: any[], breadcrumb: string): any {
    if (!navigationItems || !navigationItems.length) return;
    for (const item of navigationItems) {
      const result =
        item.label === breadcrumb
          ? item
          : this.getNavItem(item.children, breadcrumb);
      if (result) return result;
    }
  }
}
