import { Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import { HelperService, SharedService, BannerService } from '@services/index';

@Component({
  selector: 'aff-banner-promo-email',
  templateUrl: './banner-promo-email.component.html',
  styleUrls: ['./banner-promo-email.component.scss']
})
export class BannerPromoEmailComponent implements OnInit {

   @Input() set bannersAddForm(options) {
    HelperService.fillFormValues(options, this.bannerPromoemailForm);
  }

  public bannerPromoemailForm: FormGroup;
  @Output() private getObjValues = new EventEmitter<any>();

  generatorValues;

  public imageGenerator: boolean = false;

  errorObj: any = {};
  variables: string[] = [''];


  constructor(
    private sharedService: SharedService,
    private formBuilder: FormBuilder,
    private bannerService: BannerService ) {
    this.bannerPromoemailForm = this.formBuilder.group({
      html_code: new FormControl(null, []),
      subject: new FormControl(null, []),
      upload_file: new FormControl(null, [Validators.required])
    });
  }

  ngOnInit() {
      // check click saved button
      this.bannerService.clickSaved$
      .subscribe(clicked => {
        if (clicked) {
          // check validations
          this.errorObj = HelperService.checkValidation(this.bannerPromoemailForm);
        }
      })


  }


  onChangeFormValue(options) {
    switch(options.name) {
      case 'upload_file':
        this.uploadImage(options.value);
        this.getObjValues.emit({ [options.name]: options.value });
        break;

      default :
      this.bannerPromoemailForm.patchValue({ [options.name]: options.value });
      this.getObjValues.emit({ [options.name]: options.value });
    }
  }

  uploadImage(files) {
    this.sharedService.onLoaderToggle(true);
    let imageFile = HelperService.createFormData(files);

    this.sharedService.uploadImage(imageFile, 'promotion-tool')
      .then(res => {
        // this.imageId = res['url'];
        this.bannerPromoemailForm.patchValue({ upload_file: res['id'] });
        this.sharedService.onLoaderToggle(false);
        this.getObjValues.emit({ upload_file: res['id'] });
        this.imageGenerator = true;

        this.generatorValues = res;
      })
      .catch(err => {
        this.sharedService.onLoaderToggle(false);
        this.imageGenerator = false;
      })
  }

  popupToggle(value) {
    this.imageGenerator = value;
  }


  deleteImage() {
    this.bannerPromoemailForm.patchValue({ upload_file: null });
    this.getObjValues.emit({ upload_file: null });
  }

}
