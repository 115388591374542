import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Output() prev = new EventEmitter()
  @Output() next = new EventEmitter()
  @Input() current: number = 0
  @Input() maxPages: number = 0
  @Input() total: number = 0
  @Input() perPage: number = 0

  constructor() {
  }

  ngOnInit() {
  }

  handlePrevClick() {
    this.prev.emit(this.current)
  }

  handleNextClick() {
    this.next.emit(this.current)
  }

}
