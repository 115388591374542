import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { HelperService, BannerService } from '@services/index';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import * as ENUMS from "@enums/index";

@Component({
  selector: 'aff-banner-text-link',
  templateUrl: './banner-text-link.component.html',
  styleUrls: ['./banner-text-link.component.scss']
})
export class BannerTextLinkComponent implements OnInit {

  // set values from parent component
  @Input() set bannersAddForm(options) {
    HelperService.fillFormValues(options, this.bannerTextlinkForm)
  }

  public bannerTextlinkForm: FormGroup;

  @Output() private getObjValues = new EventEmitter<any>();

  wrapperForBanner: any = {};

  errorObj: any = {};

  wrappers = HelperService.enumToObjectArray(ENUMS.WrapperForBanner);
  targetAttr = HelperService.enumToObjectArray(ENUMS.TargetAttribut);

  constructor(private formBuilder: FormBuilder, private bannerService: BannerService) {
    this.bannerTextlinkForm = this.formBuilder.group({
      banner_wrapper: new FormControl(null),
      title: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
      text: new FormControl(null),
      target_attr: new FormControl(null)
    });
  }

  ngOnInit() {
    this.wrapperForBanner = HelperService.enumToObject(ENUMS.WrapperForBanner);
    // check click saved button
    this.bannerService.clickSaved$
      .subscribe(clicked => {
        if (clicked) {
          // check validations
          this.errorObj = HelperService.checkValidation(this.bannerTextlinkForm);
        }
      })

      console.log(this.wrappers)
  }

  onChangeFormValue(options): void {
    console.log(options)
    this.bannerTextlinkForm.patchValue({ [options.name]: options.value });
    this.getObjValues.emit({ [options.name]: options.value });
  }
}
