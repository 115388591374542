import { createReducer, on } from "@ngrx/store";
import {
  fetchEventTypes,
  fetchEventTypesSuccess,
  fetchEventTypesFailure,
  getEventType,
  getEventTypeSuccess,
  getEventTypeFailure,
  addEventType,
  addEventTypeSuccess,
  addEventTypeFailure,
  updateEventType,
  updateEventTypeSuccess,
  updateEventTypeFailure,
  deleteEventType,
  deleteEventTypeSuccess,
  deleteEventTypeFailure,
  fetchClick,
  fetchClickSuccess,
  fetchClickFailure,
  fetchParentEventTypes,
  fetchParentEventTypesSuccess,
} from "./tracking.action";

import { IState } from "@models/index";

const initialState: IState = {
  isLoaded: false,
  data: [],
  selectedItem: {},
  error: null,
  total: 0,
  maxPages: 0,
  perPage: 0,
  currentPage: 0,
};

const _eventTypeReducer = createReducer(
  initialState,
  on(fetchParentEventTypes, (state) => {
    return state;
  }),
  on(fetchParentEventTypesSuccess, (state, { parents }) => {
    const options = { ...state.options, parents: parents.data };

    return { ...state, options };
  }),
  on(fetchEventTypes, (state) => {
    return state;
  }),
  on(fetchEventTypesSuccess, (state, { eventType }) => {
    const payload = {
      isLoaded: true,
      data: eventType.data,
      total: eventType.total,
      maxPages: eventType.maxPages,
      perPage: eventType.perPage,
      currentPage: eventType.currentPage,
      selectedItem: {},
    };
    return { ...state, ...payload };
  }),
  on(fetchEventTypesFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  }),

  on(getEventType, (state, { id }) => {
    const payload = {
      isLoaded: false,
    };
    return { ...state, ...payload };
  }),
  on(getEventTypeSuccess, (state, { eventType }) => {
    const payload = {
      isLoaded: true,
      selectedItem: eventType,
    };
    return { ...state, ...payload };
  }),
  on(getEventTypeFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  }),
  on(updateEventType, (state, { eventType }) => {
    const payload = {
      isLoaded: false,
    };
    return { ...state, ...payload };
  }),
  on(updateEventTypeSuccess, (state, { eventType }) => {
    const index = state.data.findIndex((type) => type.id === eventType.id);
    state[index] = eventType;
    const payload = {
      isLoaded: true,
      selectedItem: eventType,
    };
    return { ...state, ...payload };
  }),
  on(updateEventTypeFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  }),
  on(addEventType, (state, { eventType }) => {
    const payload = {
      isLoaded: false,
    };
    return { ...state, ...payload };
  }),
  on(addEventTypeSuccess, (state, { eventType }) => {
    const payload = {
      isLoaded: true,
    };
    return { ...state, ...payload };
  }),
  on(addEventTypeFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  }),

  on(deleteEventType, (state, { id }) => {
    const payload = {
      isLoaded: false,
    };
    return { ...state, ...payload };
  }),
  on(deleteEventTypeSuccess, (state, { id }) => {
    const payload = {
      isLoaded: true,
      // data: state.data.filter(item => item.id !== id)
    };
    return { ...state, ...payload };
  }),
  on(deleteEventTypeFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  }),
  on(fetchClick, (state, { page, id }) => {
    return state;
  }),
  on(fetchClickSuccess, (state, { click }) => {
    const payload = {
      isLoaded: true,
      data: click.data,
      total: click.total,
      maxPages: click.maxPages,
      perPage: click.perPage,
      currentPage: click.currentPage,
      // selectedItem: click.data
    };
    return { ...state, ...payload };
  }),
  on(fetchClickFailure, (state, { error }) => {
    const payload = {
      error,
    };
    return { ...state, ...payload };
  })
);

export function eventTypeReducer(state, action) {
  return _eventTypeReducer(state, action);
}
