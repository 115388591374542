import { createSelector, createFeatureSelector } from "@ngrx/store";

import { IState } from "@models/index";

export const eventTypeSelector = createFeatureSelector<IState>("eventType");
export const getParentEventTypesSelector = createSelector(
  eventTypeSelector,
  (state: IState) => (state.options ? state.options.parents : [])
);
export const getEventTypesSelector = createSelector(
  eventTypeSelector,
  (state: IState) => state
);
export const getEventTypeSelector = createSelector(
  eventTypeSelector,
  (state: IState) => state.selectedItem
);
