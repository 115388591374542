import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { filter, takeUntil, tap } from "rxjs/operators";

import {
  AffiliateService,
  ChannelService,
  EventCategoryService,
  PageService,
  ReportService,
} from "@services/index";
import { IState } from "@models/index";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "aff-campaigns",
  templateUrl: "./campaigns.component.html",
  styleUrls: ["./campaigns.component.scss"],
})
export class CampaignsPage implements OnInit, OnDestroy {
  form: FormGroup = new FormGroup({
    category_id: new FormControl(),
    channel_id: new FormControl(),
    from: new FormControl(),
    to: new FormControl(),
    userId: new FormControl(null),
  });

  rows: any[] = [];

  events: any[] = [];
  categories: any[] = [];
  affiliates: any[] = [];
  channels: any[] = [];

  datePickerOptions: any;

  unsubscribe$ = new Subject<void>();

  constructor(
    private reportService: ReportService,
    private categoryService: EventCategoryService,
    private pageService: PageService,
    private affiliateService: AffiliateService,
    private channelService: ChannelService,
    private route: Router
  ) {}

  ngOnInit() {
    this.getCategories();
    this.getAffiliates();
    this.getChannels();

    this.pageService
      .getDatePickerOptionsSelector()
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((options) => (this.datePickerOptions = options))
      )
      .subscribe();

    this.categoryService
      .getEventCategoriesSelector()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ data }) => !!data.length),
        tap(({ data }) => (this.categories = data)),
        tap(({ data }) => (this.events = data[0].event_types)),
        tap(({ data }) => this.form.controls.category_id.patchValue(data[0].id))
      )
      .subscribe();

    this.affiliateService
      .getAffiliatesSelector()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ data }) => !!data.length),
        tap(({ data }) => (this.affiliates = data))
      )
      .subscribe();

    this.channelService
      .getChannelsSelector()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((list) => !!list.data.length),
        tap((list) => (this.channels = list.data))
      )
      .subscribe();

    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((value) => {
          const data = { ...value };
          if (data.from) {
            data.from = data.from.formatted;
          }
          if (data.to) {
            data.to = data.to.formatted;
          }
          // this.route.navigate(
          //     [],
          //     {
          //         queryParams: data,
          //         queryParamsHandling: 'merge'
          //     }
          // );
          this.reportService.fetchCampaigns(1, { ...data });
        })
      )
      .subscribe();

    this.reportService
      .getReportsSelector()
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((state) => this.storeRows(state))
      )
      .subscribe();
  }

  setEvent(e) {
    this.events = e.event_types;
  }

  private getAffiliates() {
    this.affiliateService.fetchAffiliates(1, { full: 1 });
  }

  private getChannels() {
    this.channelService.fetchChannels(1, {});
  }

  private getCategories() {
    this.categoryService.fetchEventCategories(1, {
      with_events: 1,
    });
  }

  private storeRows(state: IState) {
    if (state.isLoaded) {
      this.rows = [];
    }
    this.rows = state.data.map((item) => {
      if (!item.events) return {};

      this.events.forEach((event, index) => {
        const prevEvents =
          index === 0
            ? item.clicks
            : item.events[this.events[index - 1].id] || 0;
        const totalEvents = item.events[event.id] || 0;

        item[event.id] = totalEvents;
        item[event.id + "_rate"] = ((totalEvents / prevEvents) * 100 || 0)
          .toFixed(2)
          .replace(/(\.0+|0+)$/, "");

        event.childrens.forEach((children) => {
          item[children.id] = item.events[children.id] || 0;
        });
      });

      item.commission = item.commissions || 0;
      item.commissionRate = ((item.commission / item.clicks) * 100 || 0)
        .toFixed(2)
        .replace(/(\.0+|0+)$/, "");

      return item;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
