import { createReducer, on } from "@ngrx/store";
import {
  fetchChannel,
  fetchChannelSuccess,
  fetchChannelFailure,
  getChannel,
  getChannelSuccess,
  getChannelFailure,
} from "./channel.actions";

import { IState } from "@models/index";

const initialState: IState = {
  isLoaded: false,
  data: [],
  selectedItem: {},
  error: null,
  total: 0,
  maxPages: 0,
  perPage: 0,
  currentPage: 0,
};

const _channelReducer = createReducer(
  initialState,
  on(fetchChannel, (state, { page, filterOptions }) => {
    const payload = {
      ...state,
      isLoaded: false,
      isLoading: true,
    };
    return { ...state, ...payload };
  }),
  on(fetchChannelSuccess, (state, { channel }) => {
    const payload = {
      isLoaded: true,
      isLoading: false,
      data: channel.data,
      total: channel.total,
      maxPages: channel.maxPages,
      perPage: channel.perPage,
      currentPage: channel.currentPage,
      selectedItem: {},
    };

    return { ...state, ...payload };
  }),
  on(fetchChannelFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      isLoading: false,
      error,
    };
    return { ...state, ...payload };
  }),
  on(getChannel, (state, { id }) => {
    const payload = {
      isLoaded: false,
      isLoading: true,
    };
    return { ...state, ...payload };
  }),
  on(getChannelSuccess, (state, { channel }) => {
    const payload = {
      isLoaded: true,
      isLoading: false,
      selectedItem: channel,
    };
    return { ...state, ...payload };
  }),
  on(getChannelFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      isLoading: false,
      error,
    };
    return { ...state, ...payload };
  })
);

export function channelReducer(state, action) {
  return _channelReducer(state, action);
}
