import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
  BannerService,
  CampaignService,
  HelperService,
  PageService,
  SharedService
} from '@services/index';
import {Observable, Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {IState} from "@models/index";
import {BannerSizeOption, ObjectFit} from "@enums/index";
import {CampaignUrlStore} from "@services/campaign/campaign-url.store";

@Component({
  selector: 'aff-banners-add-page',
  templateUrl: './banners-add-page.component.html',
  styleUrls: ['./banners-add-page.component.scss'],
})
export class BannersAddPage implements OnInit, OnDestroy {
  DESCRIPTION_MAX_VALUE = 300
  public bannersAddForm: FormGroup;
  disableDestUrls: boolean = true;

  iframePreview: boolean = false;
  htmlContentAppear: boolean = false;
  wysiwygContentAppear: boolean = false;
  bannerID: string = '';

  banner$: Observable<any>;
  campaing$: Observable<IState>;
  destinationUrl$: Observable<any>;

  errorObj: any = {};
  variables: string[] = ['variables'];

  htmlcode;
  campaignID;

  bannerType: string = "";
  windowMode: string[] = ['Window', 'Opaque', 'Transparent'];
  previews: string[] = ['Affiliate'];
  private unsubscribe$ = new Subject<void>();
  editMode: boolean = false;

  saved: boolean = false;

  bannerSizeOption = BannerSizeOption;
  objectFit = ObjectFit;

  linkTypes: any[] = [
    {
      name: 'Link',
      value: 'link'
    },
    {
      name: 'Text Link',
      value: 'text_link'
    },
    {
      name: 'Image Banner',
      value: 'image_banner'
    },
    {
      name: 'HTML Banner',
      value: 'html_banner'
    },
    {
      name: 'Promo Email',
      value: 'promo_email'
    },
    {
      name: 'Simple PDF',
      value: 'simple_pdf'
    },
    {
      name: 'Product catalog template',
      value: 'product_catalog_template'
    }
  ]

  campaigns: any = [];
  campaignsSearch = new FormControl();
  selectedCampaign: any;

  destinationUrls: any = [];
  destinationUrlsSearch = new FormControl();
  selectedDestUrls: any;

  get descriptionLength() {
    return this.bannersAddForm.controls['description'].value && this.bannersAddForm.controls['description'].value.length
  }

  constructor(
    private formBuilder: FormBuilder,
    private bannerService: BannerService,
    private route: ActivatedRoute,
    private router: Router,
    private campaignService: CampaignService,
    private pageService: PageService,
    private sharedService: SharedService,
    private campaignUrlStore: CampaignUrlStore) {

    this.bannersAddForm = this.formBuilder.group({
        type: new FormControl('link'),
        name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
        hidden_banner: new FormControl(false),
        campaign_id: new FormControl(null, [Validators.required]),
        description: new FormControl(null, [Validators.maxLength(300)]),
        // destination_url: new FormControl(null),
        destination_url_id: new FormControl(null, [Validators.required]),
        additional_fields: new FormGroup({
          banner_wrapper: new FormControl(null),
          title: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
          text: new FormControl(null, [Validators.required]),
          target_attr: new FormControl(null, [Validators.required]),
          banner_size: new FormControl(this.bannerSizeOption.UNDEFINED, [Validators.required]), //img banner
          object_fit: new FormControl(this.objectFit.CONTAIN, [Validators.required]), //img banner
          width: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]), //img banner
          height: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]), //img banner
          banner_sizes: new FormControl(null), // img banner
          img_alt_text: new FormControl(null, [Validators.required]), //img banner
          upload_file: new FormControl(null, [Validators.required]), // img banner
          html_code: new FormControl(null, [Validators.required]), // HTML banner
          subject: new FormControl(null, [Validators.required, Validators.maxLength(100)]), // promo email banner
          pdf_name: new FormControl(null, [Validators.required, Validators.pattern("^[0-9a-zA-Z]+$"), Validators.maxLength(50)]) // pdf  banner
        })
      },
      {
        // validator: HelperService.urlValidator('destination_url')
      }
    );

    this.banner$ = bannerService.getBannersSelector();
    this.campaing$ = this.campaignService.getCampaignsSelector();
    this.destinationUrl$ = this.campaignUrlStore.selectCampaignUrls();

    //set breadcrum
    pageService.changePageInfo({breadcrum: ['Promotion Tools', 'Add New Promotion Tool']});
  }

  ngOnInit() {
    // get banner id
    this.bannerID = this.route.snapshot.params.id;
    if (this.bannerID) {
      this.bannerService.fetchBanner(this.bannerID);
      this.disableDestUrls = false;
    }
    this.banner$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {

        const banner = state.selectedItem;
        // check mode
        if (HelperService.isObjectEmpty(banner)) {
          return this.editMode = false;
        }

        this.campaignUrlStore.fetchUrls(1, banner.campaign_id);
        this.destinationUrl$
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(state => {
            if (state.isLoaded) {
              this.destinationUrls = state.data;
              console.log(this.destinationUrls)
            }
          })

        this.editMode = true;
        this.pageService.changePageInfo({breadcrum: ['Promotion Tools', banner.name]});

        // fill form values
        console.log(banner)
        HelperService.fillFormValues(banner, this.bannersAddForm);
        this.bannersAddForm.controls["additional_fields"].patchValue({upload_file: banner.image_id});

        banner["additional_fields"].forEach(fields => {
          this.bannersAddForm.controls["additional_fields"].patchValue({[fields.key]: fields.value});
        })

        this.selectedCampaign = state.selectedItem.campaign.name;
        this.selectedDestUrls = state.selectedItem.destination_url.url;

      });

    // owner search
    this.campaignsSearch.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(keyword => this.campaignService.fetchCampaigns(1, {keyword}));

    // get campaign list
    this.getCampaigns();

  }

  previewClicked(value) {
    if (value) {
      this.sharedService.getGenerateBanner(this.bannersAddForm.value)
        .then(res => {
          this.htmlcode = res;
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  getCampaigns() {
    this.campaignService.fetchCampaigns(1, {});
    // listen campaign
    this.campaing$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        this.campaigns = state.data;
      })
  }

  getSearchValue(options): void {
    // search value in managers
    this.campaignsSearch.patchValue(options.term);
  }

  onChangeCampaignSelection(campaign): void {
    this.selectedDestUrls = [];
    this.bannersAddForm.patchValue({campaign_id: campaign ? campaign.id : null});
    if (campaign) {
      this.disableDestUrls = false;
      this.campaignID = campaign.id
      // destination urls fetch
      this.campaignUrlStore.fetchUrls(1, this.campaignID);
      this.destinationUrl$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(state => {
          if (state.isLoaded) {
            this.destinationUrls = state.data;
          }
        })
    } else {
      this.disableDestUrls = true;
    }

  }

  onChangeDestUrlsSelection(url) {
    this.bannersAddForm.patchValue({destination_url_id: url.id});
  }

  getUrlsSearchValue(options) {
    this.campaignsSearch.patchValue(options.term);
  }

  getObjValues(objValues: any) {
    const key = Object.keys(objValues)[0];
    this.bannersAddForm.controls["additional_fields"].patchValue({[key]: objValues[key]});
  }

  htmlContent() {
    this.htmlContentAppear = true;
    this.wysiwygContentAppear = false;
  }

  wysiwygContent() {
    this.wysiwygContentAppear = true;
    this.htmlContentAppear = false;
  }

  onChangeFormValue(options) {
    switch (options.name) {
      case "back":
        this.router.navigate(['/promotion-tools'])
        break;

      case 'submit' :
        this.saveBanner();
        break;

      case 'hidden_banner':
        options.checked ? this.bannersAddForm.patchValue({[options.name]: true})
          : this.bannersAddForm.patchValue({[options.name]: false});
        break;

      case 'width':
      case 'height':
        this.bannersAddForm.patchValue({[options.name]: parseInt(options.value)});
        break;

      case 'campaign_id':
        this.bannersAddForm.patchValue({[options.name]: parseInt(options.value)});
        break;

      case 'destination_url_id':
        this.bannersAddForm.patchValue({[options.name]: parseInt(options.value)});
        break;

      default :
        this.bannersAddForm.patchValue({[options.name]: options.value});
    }
  }

  saveBanner() {
    this.bannerService.onClickSavedToggle(true);
    // check validations
    this.errorObj = HelperService.checkValidation(this.bannersAddForm);

    if (this.bannersAddForm.value.type === 'product_catalog_template') {
      delete this.errorObj['destination_url_id']
    }
    console.log(this.errorObj)
    const additionalError = HelperService.checkValidation(this.bannersAddForm.controls['additional_fields'] as FormGroup);
    this.errorObj = {...this.errorObj, ...additionalError}

    this.checkDisableValueValidation();
    if (HelperService.isObjectEmpty(this.errorObj)) {
      // save or update values
      this.editMode ? this.bannerService.updateBanner({id: this.bannerID, ...this.bannersAddForm.value}) : this.bannerService.addBanner(this.bannersAddForm.value);
      this.saved = true;
    }
    if (this.saved) {
      setTimeout(() => {
        this.saved = false;
      }, 2000)
    }
  }

  checkDisableValueValidation() {
    const formValues = this.bannersAddForm.value;
    switch (formValues.type) {
      case 'link' :
      case 'product_catalog_template':
        delete this.errorObj['banner_wrapper']; // text link
        delete this.errorObj['seo_string'];// text link
        delete this.errorObj['title']; // text link
        delete this.errorObj['text'];  // text link
        delete this.errorObj['target_attr']; // text link, img, flash
        delete this.errorObj['banner_size']; // img banner
        delete this.errorObj['object_fit']; // img banner
        delete this.errorObj['width']; // img banner
        delete this.errorObj['height']; // img banner
        delete this.errorObj['banner_sizes']; // img banner
        delete this.errorObj['img_alt_text']; // img banner
        delete this.errorObj['upload_file']; // img,flash,html
        delete this.errorObj['flash_file_type']; // html
        delete this.errorObj['html_code']; // html
        delete this.errorObj['iframe_pre_size']; // html
        delete this.errorObj['subject']; // promo
        delete this.errorObj['pdf_name']; // pdf
        delete this.errorObj['pdf_description']; // pdf
        break;

      case 'text_link' :
        delete this.errorObj['banner_wrapper']; // text link
        delete this.errorObj['seo_string'];// text link
        delete this.errorObj['text'];  // text link
        delete this.errorObj['target_attr']; // text link, img, flash
        delete this.errorObj['banner_size']; // img banner
        delete this.errorObj['object_fit']; // img banner
        delete this.errorObj['width']; // img banner
        delete this.errorObj['height']; // img banner
        delete this.errorObj['banner_sizes']; // img banner
        delete this.errorObj['img_alt_text']; // img banner
        delete this.errorObj['upload_file']; // img,flash,html
        delete this.errorObj['flash_file_type']; // html,
        delete this.errorObj['html_code'];
        delete this.errorObj['iframe_pre_size']; // html
        delete this.errorObj['subject']; // promo
        delete this.errorObj['pdf_name']; // pdf
        delete this.errorObj['pdf_description']; // pdf
        break;

      case 'image_banner' :
        delete this.errorObj['banner_size']; // img banner
        delete this.errorObj['width']; // img banner
        delete this.errorObj['height']; // img banner
        delete this.errorObj['banner_sizes']; // img banner
        delete this.errorObj['banner_wrapper']; // text link
        delete this.errorObj['seo_string'];// text link
        delete this.errorObj['title']; // text link
        delete this.errorObj['text'];  // text link
        delete this.errorObj['target_attr']; // text link, img, flash
        delete this.errorObj['upload_file']; // img,flash,html
        delete this.errorObj['flash_file_type']; // html
        delete this.errorObj['html_code']; // html
        delete this.errorObj['iframe_pre_size']; // html
        delete this.errorObj['subject']; // promo
        delete this.errorObj['pdf_name']; // pdf
        delete this.errorObj['pdf_description']; // pdf
        break;

      case 'html_banner' :
        delete this.errorObj['banner_wrapper']; // text link
        delete this.errorObj['seo_string'];// text link
        delete this.errorObj['title']; // text link
        delete this.errorObj['text'];  // text link
        delete this.errorObj['target_attr']; // text link, img, flash
        delete this.errorObj['banner_size']; // img banner
        delete this.errorObj['object_fit']; // img banner
        delete this.errorObj['width']; // img banner
        delete this.errorObj['height']; // img banner
        delete this.errorObj['banner_sizes']; // img banner
        delete this.errorObj['img_alt_text']; // img banner
        delete this.errorObj['upload_file']; // img,flash,html
        delete this.errorObj['flash_file_type']; // html
        // delete this.errorObj['html_code']; // html
        delete this.errorObj['iframe_pre_size']; // html
        delete this.errorObj['subject']; // promo
        delete this.errorObj['pdf_name']; // pdf
        delete this.errorObj['pdf_description']; // pdf
        break;

      case 'promo_email' :
        delete this.errorObj['banner_wrapper']; // text link
        delete this.errorObj['seo_string'];// text link
        delete this.errorObj['title']; // text link
        delete this.errorObj['text'];  // text link
        delete this.errorObj['target_attr']; // text link, img, flash
        delete this.errorObj['banner_size']; // img banner
        delete this.errorObj['object_fit']; // img banner
        delete this.errorObj['width']; // img banner
        delete this.errorObj['height']; // img banner
        delete this.errorObj['banner_sizes']; // img banner
        delete this.errorObj['img_alt_text']; // img banner
        delete this.errorObj['upload_file']; // img,flash,html
        delete this.errorObj['flash_file_type']; // html
        delete this.errorObj['html_code']; // html
        delete this.errorObj['iframe_pre_size']; // html
        delete this.errorObj['pdf_name']; // pdf
        delete this.errorObj['pdf_description']; // pdf
        break;

      case 'simple_pdf' :
        delete this.errorObj['banner_wrapper']; // text link
        delete this.errorObj['seo_string'];// text link
        delete this.errorObj['title']; // text link
        delete this.errorObj['text'];  // text link
        delete this.errorObj['target_attr']; // text link, img, flash
        delete this.errorObj['banner_size']; // img banner
        delete this.errorObj['object_fit']; // img banner
        delete this.errorObj['width']; // img banner
        delete this.errorObj['height']; // img banner
        delete this.errorObj['banner_sizes']; // img banner
        delete this.errorObj['img_alt_text']; // img banner
        delete this.errorObj['upload_file']; // img,flash,html
        delete this.errorObj['flash_file_type']; // html
        delete this.errorObj['html_code']; // html
        delete this.errorObj['iframe_pre_size']; // html
        delete this.errorObj['subject']; // promo
        break;
    }

    // scroll on error element
    HelperService.scrollOnErrorElement(this.errorObj);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
