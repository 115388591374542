import { createAction, props } from "@ngrx/store";

export const fetchChannel = createAction(
  "[Channel] Fetch Channels",
  props<{ page; filterOptions }>()
);

export const fetchChannelSuccess = createAction(
  "[Channel] Fetch Channels Success",
  props<{ channel }>()
);

export const fetchChannelFailure = createAction(
  "[Channel] Fetch Channels Faild",
  props<{ error }>()
);

export const getChannel = createAction(
  "[Channel] Get Channel",
  props<{ id }>()
);

export const getChannelSuccess = createAction(
  "[Channel] Get Channel Success",
  props<{ channel }>()
);

export const getChannelFailure = createAction(
  "[Channel] Get Channel Failure",
  props<{ error }>()
);
