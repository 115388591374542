import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import {
  fetchEventTypes,
  getEventType,
  addEventType,
  updateEventType,
  deleteEventType,
  fetchClick,
  fetchParentEventTypes,
} from "../../store/tracking/tracking.action";
import {
  getEventTypeSelector,
  getEventTypesSelector,
  getParentEventTypesSelector,
} from "@store/tracking/tracking.selectors";

import { HelperService } from "../helper/helper.service";
import { SharedService } from "@services/shared/shared.service";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TrackingService {
  backendUrl = environment.backendUrl;

  private _clickSaved = new Subject<boolean>();
  clickSaved$ = this._clickSaved.asObservable();

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private store: Store<{ authToken: string }>
  ) {}

  onClickSavedToggle(clicked: boolean) {
    this._clickSaved.next(clicked);
  }

  getEventTypeSelector() {
    return this.store.select(getEventTypeSelector);
  }

  getParentEventTypesSelector() {
    return this.store.select(getParentEventTypesSelector);
  }

  getEventTypesSelector() {
    return this.store.select(getEventTypesSelector);
  }

  fetchParentEventTypes(page, filterOptions) {
    this.store.dispatch(fetchParentEventTypes({ page, filterOptions }));
  }

  fetchEventTypes(page, filterOptions) {
    this.store.dispatch(fetchEventTypes({ page, filterOptions }));
  }

  fetchEventType(id) {
    this.store.dispatch(getEventType({ id }));
  }

  addEventType(eventType) {
    this.store.dispatch(addEventType({ eventType }));
  }

  updateEventType(eventType) {
    this.store.dispatch(updateEventType({ eventType }));
  }

  deleteEventType(id: number) {
    this.store.dispatch(deleteEventType({ id }));
  }

  fetchClick(page, id: string): void {
    this.store.dispatch(fetchClick({ page, id }));
  }

  getParentEventTypes(page: number, filterOptions: any = {}) {
    filterOptions.parents_only = 1;

    return this.getEventTypes(page, filterOptions);
  }

  getEventTypes(page: number, filterOptions: any = {}) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);
    return this.http.get(`${this.backendUrl}/event-types?${queryString}`);
  }

  getEventType(id: string) {
    return this.http.get(`${this.backendUrl}/event-types/event-type/${id}`);
  }

  insertEventType(body) {
    return this.http.post(`${this.backendUrl}/event-types/event-type`, body);
  }

  renewEventType(body) {
    return this.http.patch(
      `${this.backendUrl}/event-types/event-type/${body.id}`,
      body
    );
  }

  removeEventType(id) {
    return this.http.delete(`${this.backendUrl}/event-types/event-type/${id}`);
  }

  getClick(page, id: string) {
    const queryparams = { ...{ page } };
    const queryString = HelperService.generateQueryParams(queryparams);
    return this.http.get(
      `${this.backendUrl}/reports/click/${id}/events?${queryString}`
    );
  }
}
