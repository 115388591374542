import { Component, OnDestroy, OnInit } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { Observable, zip, Subject } from "rxjs";

import { SharedService, PageService, HelperService } from "@services/index";
import { NavigationState } from "@models/index";
import { Resources } from "@models/iPage";

/**
 * Sidebar Component
 */
@Component({
  selector: "aff-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  navigationItems: any[] = [];
  showNavigation: boolean = false;
  role: any = {};
  resources: any[] = [];
  childresources: any[] = [];
  count = 0;

  sidebar$: Observable<boolean>;
  navigation$: Observable<NavigationState>;
  resources$: Observable<Resources>;
  role$: Observable<boolean>;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private sharedService: SharedService,
    private pageService: PageService
  ) {
    this.sidebar$ = sharedService.getSidebarSelector();
    this.navigation$ = sharedService.getNavigationSelector();
    this.resources$ = pageService.getRecoursesSelector();
    this.role$ = pageService.getRoleSelector();

    sharedService.fetchNavigation();
    pageService.fetchResources();
    pageService.fetchRoleByToken();
  }

  ngOnInit(): void {
    // toggle sidebar
    /* this.sidebar$.pipe(takeUntil(this.unsubscribe$)).subscribe((val) => {
      this.showNavigation = val;
    }); */

    // get navigation items by permissions
    zip(this.navigation$, this.resources$, this.pageService.getRoleSelector())
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([nav, res, role]: any) => {
        if (
          !nav.isLoaded ||
          !res.isLoaded ||
          HelperService.isObjectEmpty(role)
        ) {
          return false;
        }
        if (this.count) {
          return true;
        }
        this.count++;
        this.resources = res.data;
        this.role = role;
        this.navigationItems = nav.data;
        console.log(this.navigationItems)
        // console.log("1navItems", this.navigationItems);

        for (const key in this.navigationItems) {
          if (
            !this.checkOnRole(
              this.resources,
              this.navigationItems[key].url.substr(1)
            )
          ) {
            this.navigationItems[key].disabled = true;
          } else if (this.navigationItems[key].children) {
            for (const index in this.navigationItems[key].children) {
              const slugArr =
                this.navigationItems[key].children[index].url.split("/");
              if (
                !this.checkOnRole(
                  this.childresources,
                  slugArr[slugArr.length - 1]
                )
              ) {
                this.navigationItems[key].children[index].disabled = true;
              }
            }
          }
        }
      });
  }

  checkOnRole(resources: any[], slug: string): boolean {
    const findRes = resources.find((res) => res.slug === slug);
    if (!findRes) {
      return false;
    }
    const findPerm = this.role.permissions.find(
      (perm) => perm.resource_id === findRes.id
    );

    if (!findPerm || (findRes.readable && !findPerm.read)) {
      return false;
    }
    this.childresources = findRes.children.length
      ? findRes.children
      : resources;
    return true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
