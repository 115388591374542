import { createReducer, on } from "@ngrx/store";
import {
  fetchTopCampaigns,
  fetchTopCampaignsSuccess,
  fetchTopCampaignsFailure,
  fetchTopPromotion,
  fetchTopPromotionSuccess,
  fetchTopPromotionFailure,
  fetchTopreferringUrls,
  fetchTopreferringUrlsSuccess,
  fetchTopreferringUrlsFailure,
  fetchBalanceSheet,
  fetchBalanceSheetSuccess,
  fetchBalanceSheetFailure,
  fetchChannels,
  fetchChannelsSuccess,
  fetchChannelsFailure,
  fetchGeneratedCommission,
  fetchGeneratedCommissionSuccess,
  fetchGeneratedCommissionFailure,
  fetchTopAffiliates,
  fetchTopAffiliatesSuccess,
  fetchTopAffiliatesFailure,
  fetchAffiliateActivity,
  fetchAffiliateActivitySuccess,
  fetchAffiliateActivityFailure,
  fetchClicks,
  fetchClicksSuccess,
  fetchClicksFailure,
  fetchCampaingsReport,
  fetchCampaingsReportSuccess,
  fetchCampaingsReportFailure,
  fetchCampaigns,
  fetchCampaignsSuccess,
  fetchCampaignsFailure,
  fetchUsers,
  fetchUsersSuccess,
  fetchUsersFailure,
} from "./report.actions";

import { IState } from "@models/index";

const initialState: IState = {
  isLoaded: false,
  data: [],
  selectedItem: {},
  error: null,
  total: 0,
  maxPages: 0,
  perPage: 0,
  currentPage: 0,
};

const _reportReducer = createReducer(
  initialState,
  on(fetchUsers, (state, { page, filterOptions }) => {
    return state;
  }),
  on(fetchUsersSuccess, (state, { users }) => {
    return { ...state, data: users };
  }),
  on(fetchUsersFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  }),
  // on(fetchCampaigns, (state, { page, filterOptions }) => {
  //   return state;
  // }),
  on(fetchCampaignsSuccess, (state, { campaign }) => {
    const payload = {
      isLoaded: true,
      data: campaign.data,
      total: campaign.total,
      maxPages: campaign.maxPages,
      perPage: campaign.perPage,
      currentPage: campaign.currentPage,
      selectedItem: {},
    };
    
    return { ...state, ...payload };
  }),
  on(fetchCampaignsFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  }),
  on(fetchCampaingsReport, (state, { page, filterOptions }) => {
    return state;
  }),
  on(fetchCampaingsReportSuccess, (state, { list }) => {
    const payload = {
      isLoaded: true,
      data: list.data,
      total: list.total,
      maxPages: list.maxPages,
      perPage: list.perPage,
      currentPage: list.currentPage,
      selectedItem: {},
    };

    return { ...state, ...payload };
  }),
  on(fetchCampaingsReportFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  }),
  on(fetchTopCampaigns, (state, { page, filterOptions }) => {
    return state;
  }),
  on(fetchTopCampaignsSuccess, (state, { topCampaign }) => {
    const payload = {
      isLoaded: true,
      data: topCampaign.data,
      total: topCampaign.total,
      maxPages: topCampaign.maxPages,
      perPage: topCampaign.perPage,
      currentPage: topCampaign.currentPage,
      selectedItem: {},
    };

    return { ...state, ...payload };
  }),
  on(fetchTopCampaignsFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  }),
  on(fetchTopPromotion, (state, { page, filterOptions }) => {
    return state;
  }),
  on(fetchTopPromotionSuccess, (state, { topPromotion }) => {
    const payload = {
      isLoaded: true,
      data: topPromotion.data,
      total: topPromotion.total,
      maxPages: topPromotion.maxPages,
      perPage: topPromotion.perPage,
      currentPage: topPromotion.currentPage,
      selectedItem: {},
    };

    return { ...state, ...payload };
  }),
  on(fetchTopPromotionFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  }),
  on(fetchTopreferringUrls, (state, { page, filterOptions }) => {
    return state;
  }),
  on(fetchTopreferringUrlsSuccess, (state, { topRefUrls }) => {
    const payload = {
      isLoaded: true,
      data: topRefUrls.data,
      total: topRefUrls.total,
      maxPages: topRefUrls.maxPages,
      perPage: topRefUrls.perPage,
      currentPage: topRefUrls.currentPage,
      selectedItem: {},
    };

    return { ...state, ...payload };
  }),
  on(fetchTopreferringUrlsFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  }),
  on(fetchBalanceSheet, (state, { page, filterOptions }) => {
    return state;
  }),
  on(fetchBalanceSheetSuccess, (state, { balanceSheet }) => {
    const payload = {
      isLoaded: true,
      data: balanceSheet.data,
      total: balanceSheet.total,
      maxPages: balanceSheet.maxPages,
      perPage: balanceSheet.perPage,
      currentPage: balanceSheet.currentPage,
      selectedItem: {},
    };

    return { ...state, ...payload };
  }),
  on(fetchBalanceSheetFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  }),
  on(fetchChannels, (state, { page, filterOptions }) => {
    return state;
  }),
  on(fetchChannelsSuccess, (state, { channel }) => {
    const payload = {
      isLoaded: true,
      data: channel.data,
      total: channel.total,
      maxPages: channel.maxPages,
      perPage: channel.perPage,
      currentPage: channel.currentPage,
      selectedItem: {},
    };
    return { ...state, ...payload };
  }),
  on(fetchChannelsFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  }),
  on(fetchChannels, (state, { page, filterOptions }) => {
    return state;
  }),
  on(fetchChannelsSuccess, (state, { channel }) => {
    const payload = {
      isLoaded: true,
      data: channel.data,
      total: channel.total,
      maxPages: channel.maxPages,
      perPage: channel.perPage,
      currentPage: channel.currentPage,
      selectedItem: {},
    };
    return { ...state, ...payload };
  }),
  on(fetchChannelsFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  }),
  on(fetchGeneratedCommission, (state, { page, filterOptions }) => {
    return state;
  }),
  on(fetchGeneratedCommissionSuccess, (state, { generatedComm }) => {
    const payload = {
      isLoaded: true,
      data: generatedComm.data,
      total: generatedComm.total,
      maxPages: generatedComm.maxPages,
      perPage: generatedComm.perPage,
      currentPage: generatedComm.currentPage,
      selectedItem: {},
    };
    return { ...state, ...payload };
  }),
  on(fetchGeneratedCommissionFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  }),
  on(fetchTopAffiliates, (state, { page, filterOptions }) => {
    return state;
  }),
  on(fetchTopAffiliatesSuccess, (state, { topAffiliate }) => {
    const payload = {
      isLoaded: true,
      data: topAffiliate.data,
      total: topAffiliate.total,
      maxPages: topAffiliate.maxPages,
      perPage: topAffiliate.perPage,
      currentPage: topAffiliate.currentPage,
      selectedItem: {},
    };

    return { ...state, ...payload };
  }),
  on(fetchTopAffiliatesFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  }),
  on(fetchAffiliateActivity, (state, { page, filterOptions }) => {
    return state;
  }),
  on(fetchAffiliateActivitySuccess, (state, { affActivity }) => {
    const payload = {
      isLoaded: true,
      data: affActivity.data,
      total: affActivity.total,
      maxPages: affActivity.maxPages,
      perPage: affActivity.perPage,
      currentPage: affActivity.currentPage,
      selectedItem: {},
    };

    return { ...state, ...payload };
  }),
  on(fetchAffiliateActivityFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  }),
  on(fetchClicks, (state, { page, filterOptions }) => {
    return state;
  }),
  on(fetchClicksSuccess, (state, { click }) => {
    const payload = {
      isLoaded: true,
      data: click.data,
      total: click.total,
      maxPages: click.maxPages,
      perPage: click.perPage,
      currentPage: click.currentPage,
      selectedItem: {},
    };

    return { ...state, ...payload };
  }),
  on(fetchClicksFailure, (state, { error }) => {
    const payload = {
      isLoaded: true,
      error,
    };
    return { ...state, ...payload };
  })
);

export function reportReducer(state, action) {
  return _reportReducer(state, action);
}
