import { Component, OnInit } from '@angular/core';
import { UserStatus } from '@enums/EUserStatus';
import { BannerService, UserService, HelperService, CampaignService, AffiliateManagersService } from "@services/index";
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IState } from "@models/index";
import { Subject, Observable } from 'rxjs';

@Component({
  selector: 'aff-header-filter',
  templateUrl: './header-filter.component.html',
  styleUrls: ['./header-filter.component.scss']
})
export class HeaderFilterComponent implements OnInit {
  public userStatus = UserStatus;
  contentState: boolean = false;
  linkTypes: any[] = [
    {
      name: 'Link',
      value: 'link'
    },
    {
      name: 'Text Link',
      value: 'text_link'
    },
    {
      name: 'Image Banner',
      value: 'image_banner'
    },
    {
      name: 'HTML Banner',
      value: 'html_banner'
    },
    {
      name: 'Promo Email',
      value: 'promo_email'
    },
    {
      name: 'Simple PDF',
      value: 'simple_pdf'
    },
    {
      name: 'Product catalog template',
      value: 'product_catalog_template'
    }
  ]

  filterOptions = {
    keyword: '',
    managers: [],
    type: [],
    bannerSize: '',
    campaign: '',
    width: '',
    height: '',
    hidden_from: [],
    show_hidden: false
  };

  campaing$: Observable<IState>;
  campaigns: any = [];
  selectedCampaign: any = [];
  affiliateManagers$: Observable<any>;
  managersList: any = [];
  selectedManagers: any = [];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private bannerService: BannerService,
    private userService: UserService,
    private campaignService: CampaignService,
    private affiliateManagersService: AffiliateManagersService,
    private helpService: HelperService
  ) {
    this.campaing$ = this.campaignService.getCampaignsSelector();
    this.affiliateManagers$ = this.affiliateManagersService.getAffiliateManagersSelector();
  }

  ngOnInit() {
    this.getAffiliateManagers();
    this.getCampaigns();
  }

  onClearFilter() {
    this.filterOptions = {
      keyword: '',
      managers: [],
      type: [],
      bannerSize: '',
      campaign: '',
      width: '',
      height: '',
      show_hidden: false,
      hidden_from: []
    };
    this.bannerService.fetchBanners(1, this.filterOptions);
  }

  applyFilters(){
    this.bannerService.fetchBanners(1, this.filterOptions);
  }

  getAffiliateManagers(): void {
    this.affiliateManagersService.fetchAffiliateManagers(1, {})
    this.affiliateManagers$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        this.managersList = state.data;
      });
  }

  getCampaigns() {
    this.campaignService.fetchCampaigns(1, {});
    this.campaing$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        this.campaigns = state.data
      })
  }

  onChangeFormValue(options) {
    switch (options.name) {
      case 'type':
          this.filterOptions['type'] = options.value;
        break;

      case 'show_hidden':
        this.filterOptions.show_hidden = !this.helpService.numberToBool(this.filterOptions.show_hidden);
        break;

      case 'hidden_from':
        this.filterOptions[options.name] = options.value
        break;

      default:
        this.filterOptions[options.name] = options.value;
    }
  }

  onChangeManagersSelection(managers: any): void {
    let affiliateManagers: number[] = [managers.id];
    this.filterOptions['managers'] = affiliateManagers;
    this.selectedManagers = managers;
  }

  onChangeCampaignSelection(campaigns: any): void {
    let selectedCamp: number[] = [campaigns.id];
    this.filterOptions['campaigns'] = selectedCamp;
    this.selectedCampaign = campaigns;
  }

}
