import { createAction, props } from "@ngrx/store";

export const fetchUsers = createAction(
  "[Report] Fetch Users",
  props<{ page; filterOptions }>()
);

export const fetchUsersSuccess = createAction(
  "[Report] Fetch Users Success",
  props<{ users }>()
);

export const fetchUsersFailure = createAction(
  "[Report] Fetch Users Faild",
  props<{ error }>()
);

export const fetchCampaigns = createAction(
  "[Report] Fetch Campaigns",
  props<{ page; filterOptions }>()
);

export const fetchCampaignsSuccess = createAction(
  "[Report] Fetch Campaigns Success",
  props<{ campaign }>()
);

export const fetchCampaignsFailure = createAction(
  "[Report] Fetch Campaigns Faild",
  props<{ error }>()
);

export const fetchTopCampaigns = createAction(
  "[Report] Fetch Top Campaigns",
  props<{ page; filterOptions }>()
);

export const fetchTopCampaignsSuccess = createAction(
  "[Report] Fetch Top Campaigns Success",
  props<{ topCampaign }>()
);

export const fetchTopCampaignsFailure = createAction(
  "[Report] Fetch Top Campaigns Faild",
  props<{ error }>()
);

export const fetchTopPromotion = createAction(
  "[Report] Fetch Top Promotions",
  props<{ page; filterOptions }>()
);

export const fetchTopPromotionSuccess = createAction(
  "[Report] Fetch Top Promotions Success",
  props<{ topPromotion }>()
);

export const fetchTopPromotionFailure = createAction(
  "[Report] Fetch Top Promotions Faild",
  props<{ error }>()
);

export const fetchTopreferringUrls = createAction(
  "[Report] Fetch Top Referring Urls",
  props<{ page; filterOptions }>()
);

export const fetchTopreferringUrlsSuccess = createAction(
  "[Report] Fetch Top Referring Urls Success",
  props<{ topRefUrls }>()
);

export const fetchTopreferringUrlsFailure = createAction(
  "[Report] Fetch Top Referring Urls Faild",
  props<{ error }>()
);

export const fetchBalanceSheet = createAction(
  "[Report] Fetch Balance Sheet",
  props<{ page; filterOptions }>()
);

export const fetchBalanceSheetSuccess = createAction(
  "[Report] Fetch Balance Sheet Success",
  props<{ balanceSheet }>()
);

export const fetchBalanceSheetFailure = createAction(
  "[Report] Fetch Balance Sheet Faild",
  props<{ error }>()
);

export const fetchChannels = createAction(
  "[Report] Fetch Channel",
  props<{ page; filterOptions }>()
);

export const fetchChannelsSuccess = createAction(
  "[Report] Fetch Channel Success",
  props<{ channel }>()
);

export const fetchChannelsFailure = createAction(
  "[Report] Fetch Channel Faild",
  props<{ error }>()
);

export const fetchGeneratedCommission = createAction(
  "[Report] Fetch Generated Commission",
  props<{ page; filterOptions }>()
);

export const fetchGeneratedCommissionSuccess = createAction(
  "[Report] Fetch Generated Commission Success",
  props<{ generatedComm }>()
);

export const fetchGeneratedCommissionFailure = createAction(
  "[Report] Fetch Generated Commission Faild",
  props<{ error }>()
);

export const fetchCampaingsReport = createAction(
  "[Report] Fetch Campaings Report",
  props<{ page; filterOptions }>()
);

export const fetchCampaingsReportSuccess = createAction(
  "[Report] Fetch Campaings Report Success",
  props<{ list }>()
);

export const fetchCampaingsReportFailure = createAction(
  "[Report] Fetch Campaings Report Faild",
  props<{ error }>()
);

export const fetchTopAffiliates = createAction(
  "[Report] Fetch Top Affiliates",
  props<{ page; filterOptions }>()
);

export const fetchTopAffiliatesSuccess = createAction(
  "[Report] Fetch Top Affiliates Success",
  props<{ topAffiliate }>()
);

export const fetchTopAffiliatesFailure = createAction(
  "[Report] Fetch Top Affiliates Faild",
  props<{ error }>()
);

export const fetchAffiliateActivity = createAction(
  "[Report] Fetch Affiliate Activity",
  props<{ page; filterOptions }>()
);

export const fetchAffiliateActivitySuccess = createAction(
  "[Report] Fetch Affiliate Activity Success",
  props<{ affActivity }>()
);

export const fetchAffiliateActivityFailure = createAction(
  "[Report] Fetch Affiliate Activity Faild",
  props<{ error }>()
);

export const fetchClicks = createAction(
  "[Report] Fetch Clicks",
  props<{ page; filterOptions }>()
);

export const fetchClicksSuccess = createAction(
  "[Report] Fetch Clicks Success",
  props<{ click }>()
);

export const fetchClicksFailure = createAction(
  "[Report] Fetch Clicks Faild",
  props<{ error }>()
);
