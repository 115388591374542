import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import {
  fetchBanner,
  deleteBanner,
  addBanner,
  updateBanner,
  getBanner
} from "../../store/banner/banner.actions";
import { getBannersSelector } from '@store/banner/banner.selectors';

import { HelperService } from '../helper/helper.service';
import { SharedService } from '@services/shared/shared.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class BannerService {
  backendUrl = environment.backendUrl;

  private _clickSaved = new Subject<boolean>();
  clickSaved$ = this._clickSaved.asObservable();


  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private store: Store<{ authToken: string }>
  ) {}


  onClickSavedToggle(clicked: boolean) {
    this._clickSaved.next(clicked);
  }

  getBannersSelector() {
    return this.store.select(getBannersSelector);
  }

  fetchBanners(page, filterOptions) {
    this.store.dispatch(fetchBanner({ page, filterOptions }));
  }

  fetchBanner(id) {
    this.store.dispatch(getBanner({ id }))
  }

  deleteBanner(id) {
    this.store.dispatch(deleteBanner({ id }));
  }

  addBanner(banner) {
    this.sharedService.onLoaderToggle(true);
    this.store.dispatch(addBanner({ banner }));
  }

  updateBanner(banner) {
    this.sharedService.onLoaderToggle(true);
    this.store.dispatch(updateBanner({ banner }));
  }

  getBanners(page: number, filterOptions: any = { }) {
    const queryparams = { ...{ page }, ...filterOptions };
    const queryString = HelperService.generateQueryParams(queryparams);

    return this.http.get(`${this.backendUrl}/promotion-tools?${queryString}`);
  }

  getBanner(id: string) {
    return this.http.get(`${this.backendUrl}/promotion-tools/promotion-tool/${id}`);
  }

  removeBanner(id) {
    return this.http.delete(`${this.backendUrl}/promotion-tools/promotion-tool/${id}`);
  }

  insertBanner(banner) {
    return this.http.post(`${this.backendUrl}/promotion-tools/promotion-tool`, banner);
  }

  renewBanner(banner) {
    return this.http.patch(`${this.backendUrl}/promotion-tools/promotion-tool/${banner.id}`, banner)
  }

  getUrl(bannerId: number){
    return `${this.backendUrl}/promotion-tools/promotion-tool/${bannerId}/generate`;
  }

  removePromotionTools(payload: number[]) {
    return this.http.request('delete', `${this.backendUrl}/promotion-tools`, {
      body: {
        promotion_ids: payload
      }
    } )
  }
}
