import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-aff-button',
  templateUrl: './aff-button.component.html',
  styleUrls: ['./aff-button.component.scss']
})
export class AffButtonComponent implements OnInit {
  @Output() handleClick = new EventEmitter()

  @Input() label: string;
  @Input() type: ButtonType = "primary";
  @Input() shape: ButtonShapeType;
  @Input() size: ButtonSizeType;
  @Input() loading: boolean;
  constructor() { }

  ngOnInit() {
  }

  onCLick() {
    this.handleClick.emit()
  }
}


export type ButtonType = 'primary'|'dashed'|'danger'|'default'|'link'
export type ButtonShapeType = 'circle' | 'round'
export type ButtonSizeType = 'large' | 'small' | 'default'
